export default {
	message: {
		location: "https://www.pdnob.com/products/pdnob-pdf-online.html",
		// logo:"http://www.passfab.com/images/pdf-convert/pdf-logo.svg",
		logo: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCA1MiAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzYwODBfNzQzNiIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjUyIiBoZWlnaHQ9IjMwIj4KPHJlY3Qgd2lkdGg9IjUyIiBoZWlnaHQ9IjMwIiBmaWxsPSIjQzRDNEM0Ii8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF82MDgwXzc0MzYpIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zMC45NDI2IDYuNzA3NzRDMjcuMTYzIDEyLjA2ODUgMjMuMjY4NSAxNS4zMDQgMTkuNTEgMTUuNDMzNkMyMC4wMjc3IDE2LjEwOSAyMC40OTIxIDE2LjczMzkgMjAuOTE4OSAxNy4zMDgxQzI0LjgyNTYgMjIuNTY0NiAyNS41ODY0IDIzLjU4ODMgMzUuMzEzMyAyMC40NDY1QzM5Ljk0NjggMTguOTQ5OCA0NS42MTE0IDEzLjczOTUgNTEuMDQxMiA2LjcwNzc0TDMwLjk0MjYgNi43MDc3NFoiIGZpbGw9IiMwMDQ3ODkiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yOS43MTgzIDAuNTk3NjUzQzI2LjI1NTcgOC42NzQ5MSAyMi40NDA0IDE0LjIwNDggMTguNTgxNyAxNS42MTk3QzE4LjY5NzUgMTUuNzM4NSAxOC44MTI4IDE1Ljg1NjkgMTguOTI3NyAxNS45NzQ5QzI0LjU1ODEgMjEuNzU4MyAyOS4xNTgxIDI2LjQ4MzIgMzYuMjMzNiAyMC4wNzU4QzM5Ljk2OSAxNi42OTMxIDQ0LjQ2MTEgOS4zNDMzNiA0OC42ODggMC41OTc2NTNMMjkuNzE4MyAwLjU5NzY1M1oiIGZpbGw9IiMyNzhCRkYiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyLjM1NTZDNi41NjcyNSAyMi40NjkxIDEyLjg1MDYgMjYuMTY0OCAyNC4wNDg0IDI1LjE1NjNDMjQuMTM0MiAyNS4xNDg2IDI0LjI0OSAyNS4xNDIxIDI0LjM5MDQgMjUuMTM0MUMyNS45OTQzIDI1LjA0MzYgMzEuMDIzNyAyNC43NTk3IDM2LjEwMjMgMjAuMjYwN0MyNi4xODEzIDIxLjY1NTQgMjEuNTM5NiAxOC41NjI2IDE3Ljk0MiAxMi4zNTU2TDAgMTIuMzU1NloiIGZpbGw9IiMzQ0QwRkYiLz4KPC9nPgo8L3N2Zz4K",
		Navigation: {
			pdf: {
				// title: "PDF Toolset",
				title: "Convert PDF",
				info: ["PDF to Word", "PDF to Excel", "PDF to PPT", "PDF to TXT", "PDF to JPG", "PDF to HTML",
					"Word to PDF", "Excel to PDF", "PPT to PDF", "TXT to PDF", "Images to PDF", "HTML to PDF",
					"Compress PDF", "Merge PDF", "Split PDF", "Protect PDF", "Delete Pages", "Rotate PDF",
					"Add Watermark", "Extract Images", "Extract Pages", "Remove Watermark"
				]
				// info:[]
			},
			Other: {
				title: "All PDF Tools",
				info: []
			},
		},
		FunList: ["PDF Converter", "Split PDF", "Merge PDF", "Add Watermark", "Compress PDF", "Rotate PDF",
			"Protect PDF", "Delete Pages", "Extract Images", "Extract Pages", "Remove Watermark"
		],
		FunOperat: {
			splitPDF: {
				Operat: "Split PDF",
				Operat1: "Extract Pages",
				Split: {
					text: ["Split", "Split after every page", "Custom Range"],
					Range: ["Range", "Add Range", "Merge into one PDF", "to"]
				},
				Extract: {
					text: ["Extract", "Extract all pages", "Extract from the selected page"],
					Page: ["Page Number", "Enter page number", "Example: 1,2-3,6-8"]
				},
				tip: "You can choose up to 5 ranges to spilt the PDF into 5 PDFs. For example, set page 1 to page 4 in Range 1, that is, split page 1 to page 4 of this PDF file into a new PDF."
			},
			mergePDF: {
				Operat: "Combine PDFs",
				title: "Merge PDF",
				p: "Combine PDF files online with ease",
				add: {
					text: [
						"File：",
						"Add Files",
						"Tip: Drag the PDF to change the merge order.",
						"You cannot add more than 5 files.",
						"Drag to change the order",
						"There is only one file currently, please add more files.",
						"You cannot add more than 8 files.",
					]
				}
			},
			addWater: {
				Operat: "Apply",
				text: {
					text: ["Text", "Enter the text", "Size", "Transparency", "Position", "Rotate", "Tiled display"]
				},
				img: {
					text: ["Image", "Add image"]
				}
			},
			removeWater: {
				Operat: "Remove Watermark",
				p: "One click to remove picture watermarks and text watermarks from files",
				img: {
					text: ["Auto Removal", "Index Removal"]
				},
				text: [
					"Please enter the text of the watermark you want to remove:",
					"Please enter the text of the watermark you want to remove."
				],
				rp: [
					"Tip:",
					" Automatically identify and remove picture watermarks and text watermarks in files.",
					" Only supports removing the text watermark. If the content you enter appears in the text, the index removal will delete the relevant content, please use it with caution."
				]
			},
			compressPDF: {
				Operat: "Compress PDF",
				p: "Reduce file size without losing quality",
				Compression: {
					text: ["Highest Definition", "Regular Compression", "High Compression"],
					p: ["Best quality. Largest file size.", "Good quality. Medium file size.", "Good quality. Smallest file size."]
				}
			},
			rotatePDF: {
				Operat: "Rotate PDF",
				Rotate: {
					text: ["Rotate all", "Left", "Right"]
				}
			},
			protectPDF: {
				Operat: "Protect PDF",
				protect: {
					text: ["Password", "Enter your password", "Repeat Password", "Repeat your password"],
					tip: ["Please fill in the password twice to protect the PDF file.", "Password cannot be blank.", "Please enter the same password as above."]
				}
			},
			delatePDF: {
				Operat: "Delete Pages",
			},
			extractIMG: {
				Operat: "Extract Images",
				img: {
					text: ["Image Format", "Extraction Mode", "Extact from all pages", "Extract from the selected page",
						"Page Number", "Enter page number", "Example: 1,2-3,6-8"
					]
				}
			}

		},
		selectFile: ["Select a File", "Select Files", "Drop file here or", "Drop files here or"],
		selectText: ["Your file is secure.", "Your files are secure."],
		UploadFun: {
			title: "Uploading",
			message: [
				"Only supports processing a single file of less than 30MB and a total file of less than 100MB.",
				"Corrupted or encrypted files have been filtered",
				"Cannot process the corrupted or encrypted files.",
				"The network is abnormal. Please check the network connection.",
				"Files that do not conform to the format will be skipped",
				"Corrupted or encrypted files are not supported, please delete the files and try again."
			],
			uploading: [
				"Just wait a few seconds.",
				"Thanks for your patience.",
				"Sorry, upload failed!",
				"Start Over",
				"Please ensure that the network is stable."
			],
		},
		Generating: {
			title: "Generating a new file..."
		},
		fileTopdf: {
			button: "Convert to PDF",
			JpgToPdf: {
				text: ["Image", "Add image", "You cannot add more than 4 images.", "Drag the image to change the order.", "Convert to a PDF"]
			}
		},
		PdfTofile: {
			button: ["Converter PDF to Excel", "Converter PDF to HTML", "Converter PDF to Image", "Converter PDF to PPT", "Converter PDF to TXT",
				"Converter PDF to Word", "Converter Word to PDF", "Converter Excel to PDF", "Converter PPT to PDF", "Converter Text to PDF", "Converter Html to PDF"
			],
			text: [
				"Convert PDF to editable Word file online with ease",
				"Convert PDF to Excel spreadsheet online with ease",
				"Convert PDF to PowerPoint online with ease",
				"Convert PDF to JPG image format online with ease",
				"Convert PDF to TXT online with ease",
				"Convert PDF to HTML online with ease",
				"Convert Microsoft Word document to PDF online with ease",
				"Convert Excel to PDF online with ease",
				"Convert PowerPoint to PDF online with ease",
				"Convert TXT to PDF online with ease",
				"Convert mainstream image formats to PDF online with ease"
			],
		},
		successText: ["Your file is ready."],
		commonTip: {
			alertTip: [
				{
					//0
					poptit: "Only one file can be converted.",
					poptext: "Please select a file.",
					confirmButtonText: "OK"

				},
				{
					//1
					poptit: "This file format is not supported!",
					poptext: "The file format is not supported!",
					confirmButtonText: "OK"
				},
				{
					//2
					// poptit: "The size of the file you uploaded exceeded the limit and could not be uploaded!",
					poptit: "Tips",
					poptext: "Only supports processing a single file less than 30MB and the total file less than 100MB.",
					confirmButtonText: "OK"
				},
				{
					//3
					poptit: "Tips",
					poptext: "Cannot process the corrupted or encrypted files.",
					confirmButtonText: "OK"
				},
				{
					//4
					poptit: "Download failed!",
					poptext: "Invalid file, please generate it again.",
					confirmButtonText: 'Restart',
					cancelButtonText: 'Cancel',
				},
				{
					//5
					poptit: "Tips",
					poptext: "Leaving or reloading the page will lose data, are you sure to leave the current page?",
					confirmButtonText: "Leave",
					cancelButtonText: "Cancel"
				},
				{
					//6
					poptit: "Tips",
					poptext: "Your file has not been downloaded. Are you sure you want to leave this page?",
					confirmButtonText: "Leave",
					cancelButtonText: "Cancel"
				},
				{
					//7
					poptext: "The watermark is not recognized, please try other methods to remove it.",
				},
			]
		},
		downloadPag: {
			tip: "Please download the file now. It will be automatically deleted from our server after an hour.",
		},
		Downloading: ["Download File"],
		secure: {
			h3: {
				convert: ["Make PDF Conversion Easily", "High Fidelity", "Safe and Secure"],
				merge: ["Combine PDFs into One File", "Freely Reorder Files", "Safe and Secure"],
				compress: ["Multiple Compression Algorithms", "Lossless Compression", "Safe and Secure"],
				removeWater: ["Flexible and Practical", "Easy to Use", "Safe and Secure"]
			},
			p: {
				convert: ["PDF converter can easily convert PDF from/to Microsoft Office documents.", "Professional file conversion technology, which can quickly complete PDF conversion without losing format and quality.", "All file transfers are protected by encryption. In addition, the server will automatically destroy all uploaded files after 1 hour."],
				merge: ["It's quick and easy to merge PDF files into a single document with the PDNob PDF Online Tools.", "After adding the files you want to merge, you can drag and drop them into the order you like.", "All file transfers are protected by encryption. In addition, the server will automatically destroy all uploaded files after 1 hour."],
				compress: ["PDNob PDF Online has multiple compression algorithms to meet different scenarios, you can choose a suitable compression algorithm before compression.", "Reduce file size by compressing text as well as images in PDF files while still maintaining their quality after compression.", "All file transfers are protected by encryption. In addition, the server will automatically destroy all uploaded files after 1 hour."],
				removeWater: ["A variety of solutions to remove watermarks in files, which can effectively remove picture and text watermarks in PDF files.", "One-click to remove the picture and text watermarks in the file without teaching.", "All file transfers are protected by encryption. In addition, the server will automatically destroy all uploaded files after 1 hour."],

			}
		},
		operation: {
			title: {
				convert: "How to convert PDF files online:",
				merge: "How to merge multiple PDF files into one document:",
				compress: "How to Compress A PDF Online:",
				removeWater: "How to Remove Watermark Online:"
			},
			h3: {
				convert: ["Select or drag-and-drop your PDF files in the toolbox.", "Convert the file automatically while uploading.", "Download the converted file."],
				merge: ["Select or drag-and-drop your PDF files in the toolbox.", "Reorder the files if needed.", "Wait for the process to complete and download."],
				compress: ["Select or drag-and-drop your PDF file in the toolbox.", "Select the desired compression method.", "Wait for the process to complete and download the file."],
				removeWater: ["Select or drag-and-drop your PDF file in the toolbox.", "Select the desired watermark removal method.", "Wait for the process to complete and download the file."]
			}
		},
		Reviews: {
			title: "Reviews & Mentions for PDNob PDF",
			p: ["I am a college student. When writing a paper, I need to cite some materials. After converting the PDF into Word, I can directly cite it, which greatly saves my time.",
				"I’ve really enjoyed this for converting books to pdf. I have many workbooks that I send out to kids through online classes.",
				"I love adding nice watermarks to my journals, thanks to PDNob PDF Online for making my life easier!"],
			s: ["Luna", "Lola", "Peter"]
		},
		AllTotals: {
			title: "Try These Online PDF Tools",
			h3: ["Organize PDF", "Convert from PDF", "Edit PDF", "PDF Security", "Optimize PDF", "Convert to PDF"]
		},
		funText: {
			title: "Why Choose PDNob PDF Online?",
			h3: ["Excellent and Free", "Fast & High Quality", "Simple and Reliable to Use", "Reliable & Safe", "Strong Applicability", "High Customer Satisfaction"],
			p: [
				"Provides functions such as converting, splitting, merging, compressing PDF for free, and the processed files have no watermark.",
				"Converting and processing files quickly with high precision and no data loss.",
				"One-click to complete file processing, simple and fast.",
				"All transfers are encrypted and uploaded files are automatically and permanently deleted from the server after 1 hour.",
				"Runs efficiently in major browsers and supports all operating systems, so you can use it wherever you are.",
				"When you encounter any doubt during file processing, you can contact customer service."

			]
		},
		stars: {
			text: ["Please give your ratings", "Votes", "Share with Your Friends", "Submit"]
		},
		BuyNow: 'Buy Now',
		Contactus: 'Contact Us',
		About: 'About',
		AllTools: 'All Tools',
		OptimizePDF: 'Optimize PDF',
		Popular: 'Popular',
		DailyFreeTrial: 'Daily Free Trial：',
		Upgrade: 'Upgrade ',
		Numberoffiles: 'Files：',
		BECOMEAMEMBER: 'BECOME A MEMBER',
		Permission: 'Permission',
		FreeTrail: 'Free Trail',
		Member: 'Member',
		NumberofUsage: 'Number of Usage',
		FileSize: 'File Size',
		NumberofFiles: 'Number of Files',
		SoftwareUpgrade: 'Software Upgrade',
		TechSupport: 'Tech Support',
		DayCount: '5 times a day',
		Unlimited: 'Unlimited',
		CheckPrice: 'Buy Now',
		Login: 'Login',
		Update: 'Update',
		Gettingaccountpermissions: 'Getting account permissions...',
		authorizationInformation: 'The authorization status has been updated successfully, and you are already a member.',
		NetworkError: 'Network error.',
		netWorkExplain: 'Please make sure the network is stable, the page will be restored automatically after the network is stable.',
		FileProcessingSucceeded: 'File processing succeeded.',
		downloadExplain1: 'Your file has been processed, click ',
		downloadExplain2: ' to use this function again. For more office products, please visit our ',
		Retry: 'Retry',
		officialWebsite: 'official website.',
		OpenFolder: 'Open folder',
		SaveAs: 'Save as',
		RemainingFree: 'Remaining free usage times ',
		RemainingFree1: 'upgrade',
		RemainingFree2: ' to unlock all functions.',
		upgradePermission: 'upgrade',
		MyAccount: 'My account',
		Validuntil: 'Valid until：',
		expired: '(expired)',
		RemainingDailyFreeTrials: 'Remaining Daily Free Trials：',
		Permissioninformation: 'Permission information：',
		MemberVersion: 'Member version',
		TrialVersion: 'Trial version',
		Numberofdevicesused: 'Number of devices used：',
		Totalnumberofequipment: 'Total number of equipment：',
		upgradepermission: 'upgrade',
		Renewal: 'Renewal',
		unlimitedusing: 'You can get unlimited using times and file capacity rights by upgrading the service!',
		Refreshfailed: 'Refresh failed, check network and try again.',
		Retry: 'Retry',
		Version: 'Version',
		processingToolbox: 'PDNob PDF Toolkit is a professional PDF document processing toolbox, which provids safe and reliable services for you.',
		Registeredsuccessfully: 'Registered successfully!',
		Loginsuccessfully: 'Login successfully!',
		Modifiedsuccessfully: 'Modified successfully!',
		UploadFailed: 'Upload failed, the file upload capacity limit (4G) has been reached.',
		updatedSuccessfully: 'The authorization status has been updated successfully, and you are already a member.',
		downloadFailed: 'The file download failed, please click Save As to download again.',
		pathInvalid: 'The path is invalid, please change the path and download it again.',
		Insufficient: 'Insufficient disk space, please change the path to download again.',
		SaveAs: 'Save as',
		Cancel: 'Cancel',
		Loading: 'Loading, please wait...',
		downloadIntro1: 'Your file has been processed and automatically downloaded and saved on your computer,',
		downloadIntro2: 'click here to open the path of the file.',
		Restart: 'Restart',
		DownloadAgain: 'Redownload',
		Tip: 'Tips:',
		Tip1: '1. The download speed of the file is affected by the network bandwidth and file size. After the download is complete, the file path will be opened automatically. Please wait patiently.',
		Tip2: '2. The server will automatically delete the file after 1 hour, please complete the download within 1 hour.',
		Tip3: '3. If the processed file name is the same as the file name in the download path, the original file will be replaced.',
		downloadNetError: 'Failed to download the file, please check the network and download it again.',
		OK: 'OK',
		DownloadToast: 'The file is being downloaded, and can be viewed in the corresponding path after the download is complete.',
		DownloadErrorToast: 'The file download failed, the possible reasons are as follows:',
		DownloadErrorToast1: '1. The disk space is insufficient or the download path is not writable. Please clean up the storage of the download path or ensure that the path permissions are readable and writable, and then try the download again.',
		DownloadErrorToast2: '2. The file has been deleted by the server. The processed file will be automatically deleted by the server after 1 hour, please upload the file again and process it.',
	},
}
