<template>
  <header class="header">
    <!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark"></nav> -->
    <div class="second-header position-relative" style="justify-content: space-between; display: flex;">
      <div class="header-container">
        <div @click="pathTo" class="d-flex justify-content-between align-item-center cursor">
          <img :src="$t(`message.logo`)" alt />
          <span class="logoTitle">PDNob PDF Online</span>
        </div>
        <ul class="text-regular font-book flexible tool-list" style="padding: 0;">
          <li class="convert-tool">
            {{$t(`message.Navigation.pdf.title`)}}
            <!-- <img src="../assets/nav/down.svg" alt /> -->
            <i class="iconfont icon-up"></i>
          </li>
          <li class="merge-tool cursor" @click="goPath('/pdf-combiner?from_funlist','mergepdf')">
            {{$t(`message.Navigation.pdf.info[13]`)}}</li>
          <li class="all-tool">
            {{$t(`message.Navigation.Other.title`)}}
            <i class="iconfont icon-up"></i>
          </li>
          <div id="convert-tool-list" @mouseenter="mouseEnter('Convert')" @mouseleave="mouseLeave('Convert')">
            <div class="container flexible">
              <ul style="margin-right: 40px;" class="container-ul">
                <p>Convert from PDF</p>
                <li class="img6 cursor" @click="goPath('/convert?from_toplist&to_word','pdftoword')">
                  <img src="../assets/allTotals/PTW.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[0]`)}}</span>
                </li>
                <li class="img7 cursor" @click="goPath('/convert?from_toplist&to_jpg','pdftojpg')">
                  <img src="../assets/allTotals/PTJ.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[4]`)}}</span>
                </li>
                <li class="img8 cursor" @click="goPath('/convert?from_toplist&to_excel','pdftoexcel')">
                  <img src="../assets/allTotals/PTE.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[1]`)}}</span>
                </li>
                <li class="img9 cursor" @click="goPath('/convert?from_toplist&to_ppt','pdftoppt')">
                  <img src="../assets/allTotals/PDTPP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[2]`)}}</span>
                </li>
                <li class="img10 cursor" @click="goPath('/convert?from_toplist&to_text','pdftotxt')">
                  <img src="../assets/allTotals/PTT.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[3]`)}}</span>
                </li>
                <li class="img11 cursor" @click="goPath('/convert?from_toplist&to_html','pdftohtml')">
                  <img src="../assets/allTotals/PTH.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[5]`)}}</span>
                </li>
              </ul>
              <ul class="container-ul">
                <p>Convert to PDF</p>
                <li class="img12 cursor" @click="goPath('/convert?from_toplist&from_other_word','wordtopdf')">
                  <img src="../assets/allTotals/WTP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[6]`)}}</span>
                </li>
                <li class="img13 cursor" @click="goPath('/convert?from_toplist&from_other_jpg','jpgtopdf')">
                  <img src="../assets/allTotals/ITP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[10]`)}}</span>
                </li>
                <li class="img14 cursor" @click="goPath('/convert?from_toplist&from_other_excel','exceltopdf')">
                  <img src="../assets/allTotals/ETP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[7]`)}}</span>
                </li>
                <li class="img15 cursor" @click="goPath('/convert?from_toplist&from_other_ppt','ppttopdf')">
                  <img src="../assets/allTotals/PPTPD.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[8]`)}}</span>
                </li>
                <li class="img16 cursor" @click="goPath('/convert?from_toplist&from_other_text','txttopdf')">
                  <img src="../assets/allTotals/TTP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[9]`)}}</span>
                </li>
                <li class="cursor img21" @click="goPath('https://pdnob.passfab.com/convert','htmltopdf',true)">
                  <img src="../assets/allTotals/HTP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[11]`)}}</span>
                </li>
              </ul>
            </div>
          </div>
          <div id="all-tool-list" @mouseenter="mouseEnter('AllTotal')" @mouseleave="mouseLeave('AllTotal')">
            <div class="container flexible flex-justify-between">
              <ul class="container-ul">
                <p>{{$t(`message.AllTotals.h3[0]`)}}</p>
                <li class="cursor img1" @click="goPath('/pdf-combiner?from_funlist','mergepdf')">
                  <img src="../assets/allTotals/mergePDF.svg" alt />
                  <span>{{$t(`message.FunList[2]`)}}</span>
                </li>
                <li class="cursor img2"
                  @click="goPath('https://pdnob.passfab.com/split-pdf?from_toplist','splitpdf',true)">
                  <img src="../assets/allTotals/splitPDF.svg" alt />
                  <span>{{$t(`message.FunList[1]`)}}</span>
                </li>
                <li class="cursor img3"
                  @click="goPath('https://pdnob.passfab.com/extract-pages-from-pdf?from_funlist','extractpages',true)">
                  <img src="../assets/allTotals/extractPages.svg" alt />
                  <span>{{$t(`message.FunList[9]`)}}</span>
                </li>
                <li class="cursor img4"
                  @click="goPath('https://pdnob.passfab.com/extract-images-from-pdf?from_funlist','extractImages',true)">
                  <img src="../assets/allTotals/extractImages.svg" alt />
                  <span>{{$t(`message.FunList[8]`)}}</span>
                </li>
              </ul>
              <ul class="container-ul">
                <p>{{$t(`message.AllTotals.h3[4]`)}}</p>
                <li class="cursor img5" @click="goPath('/compress-pdf?from_funlist','compresspdf')">
                  <img src="../assets/allTotals/compressPDF.svg" alt />
                  <span>{{$t(`message.FunList[4]`)}}</span>
                </li>
              </ul>
              <ul class="container-ul">
                <p>{{$t(`message.AllTotals.h3[1]`)}}</p>
                <li class="img6 cursor" @click="goPath('/convert?from_toplist&to_word','pdftoword')">
                  <img src="../assets/allTotals/PTW.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[0]`)}}</span>
                </li>
                <li class="img7 cursor" @click="goPath('/convert?from_toplist&to_jpg','pdftojpg')">
                  <img src="../assets/allTotals/PTJ.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[4]`)}}</span>
                </li>
                <li class="img8 cursor" @click="goPath('/convert?from_toplist&to_excel','pdftoexcel')">
                  <img src="../assets/allTotals/PTE.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[1]`)}}</span>
                </li>
                <li class="img9 cursor" @click="goPath('/convert?from_toplist&to_ppt','pdftoppt')">
                  <img src="../assets/allTotals/PDTPP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[2]`)}}</span>
                </li>
                <li class="img10 cursor" @click="goPath('/convert?from_toplist&to_text','pdftotxt')">
                  <img src="../assets/allTotals/PTT.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[3]`)}}</span>
                </li>
                <li class="img11 cursor" @click="goPath('/convert?from_toplist&to_html','pdftohtml')">
                  <img src="../assets/allTotals/PTH.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[5]`)}}</span>
                </li>
              </ul>
              <ul class="container-ul">
                <p>{{$t(`message.AllTotals.h3[5]`)}}</p>
                <li class="cursor img12" @click="goPath('/convert?from_toplist&from_other_word','wordtopdf')">
                  <img src="../assets/allTotals/WTP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[6]`)}}</span>
                </li>
                <li class="cursor img13" @click="goPath('/convert?from_toplist&from_other_jpg','jpgtopdf')">
                  <img src="../assets/allTotals/ITP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[10]`)}}</span>
                </li>
                <li class="cursor img14" @click="goPath('/convert?from_toplist&from_other_excel','exceltopdf')">
                  <img src="../assets/allTotals/ETP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[7]`)}}</span>
                </li>
                <li class="cursor img15" @click="goPath('/convert?from_toplist&from_other_ppt','ppttopdf')">
                  <img src="../assets/allTotals/PPTPD.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[8]`)}}</span>
                </li>
                <li class="cursor img16" @click="goPath('/convert?from_toplist&from_other_text','txttopdf')">
                  <img src="../assets/allTotals/TTP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[9]`)}}</span>
                </li>
                <li class="cursor img21" @click="goPath('https://pdnob.passfab.com/convert','htmltopdf',true)">
                  <img src="../assets/allTotals/HTP.svg" alt />
                  <span>{{$t(`message.Navigation.pdf.info[11]`)}}</span>
                </li>
              </ul>
              <ul class="container-ul">
                <p>{{$t(`message.AllTotals.h3[2]`)}}</p>
                <li class="cursor img22" @click="goPath('/remove-watermark?from_funlist','removewatermark',true)">
                  <img src="../assets/allTotals/RW.svg" alt />
                  <span>{{$t(`message.FunList[10]`)}}</span>
                </li>
                <img src="../assets/allTotals/hot.svg" alt="" style="position: relative; top: -45px;left: 170px;">
                <li class="cursor img17" style="margin-top: 0;"
                  @click="goPath('https://pdnob.passfab.com/add-watermark-to-pdf?from_funlist','addwatermark',true)">
                  <img src="../assets/allTotals/AW.svg" alt />
                  <span>{{$t(`message.FunList[3]`)}}</span>
                </li>
                <li class="cursor img18"
                  @click="goPath('https://pdnob.passfab.com/rotate-pdf?from_funlist','rotatepdf',true)">
                  <img src="../assets/allTotals/RP.svg" alt />
                  <span>{{$t(`message.FunList[5]`)}}</span>
                </li>
                <li class="cursor img19"
                  @click="goPath('https://pdnob.passfab.com/delete-pages-from-pdf?from_funlist','deletepages',true)">
                  <img src="../assets/allTotals/DP.svg" alt />
                  <span>{{$t(`message.FunList[7]`)}}</span>
                </li>
              </ul>
              <ul style="margin-right: 0;" class="container-ul">
                <p>{{$t(`message.AllTotals.h3[3]`)}}</p>
                <li class="cursor img20"
                  @click="goPath('https://pdnob.passfab.com/password-protect-pdf?from_funlist','passwordprotect',true)">
                  <img src="../assets/allTotals/PP.svg" alt />
                  <span>{{$t(`message.FunList[6]`)}}</span>
                </li>
              </ul>
            </div>
          </div>
        </ul>
      </div>
      <div class="navBox" v-if="operation == 'Windows'">
        <div class="navButton" @click="goPurch"
          style="background: linear-gradient(93.53deg, #FFDE9F 28.26%, #FFD187 61.9%, #FFB341 96.78%), #5452F6; color: #1D1D1F;">
          <img src="../assets/nav/purchase.svg" alt="">
          <span>Buy Now</span>
        </div>
        <div class="navButton" style="background: #5452F6; color: #FFFFFF;" @click="download">
          <img src="../assets/nav/window.svg" alt="">
          <span>Free Download</span>
        </div>
      </div>
    </div>
  </header>
</template>
<style lang="scss" scoped>
  .navBox {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  .navButton {
    width: 155px;
    height: 32px;
    border-radius: 16px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;

    img {
      margin-right: 8px;
    }
  }

  .logoTitle {
    margin: 0 90px 0 8px;
    font-weight: bold;
    line-height: 70px;
  }

  header {
    position: fixed;
    width: 100%;
    z-index: 20;
  }

  .header {
    box-shadow: 0px 1px 0px #e9f3ff;
  }

  .header-container {
    // max-width: 1200px;
    margin: 0 0 0 32px;
    display: flex;
    align-items: center;
    height: 100%;
    // justify-content: space-between;
    color: #001637;

    .tool-list {
      margin: 0;
      font-weight: 600;
      height: 100%;
      /* line-height: 70px; */

      li {
        padding: 0 20px;

        &.convert-tool {
          text-decoration: none;
          line-height: 70px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #3d454d;

          &:hover {

            /* background: rgba(39, 139, 255, 0.1); */
            color: #4260ff !important;
            cursor: pointer;

            i {
              display: inline-block;
              transform: rotate(180deg);
              transition: transform 0.5s;
            }

            ~#convert-tool-list {
              height: auto;
              /* padding: 32px 0; */
              padding: 40px 0;
              /* border-top: 1px solid #ccc; */
            }
          }

          img {
            margin-left: 8px;
          }
        }

        &.merge-tool {
          font-weight: 700;
          text-decoration: none;
          line-height: 70px;
          font-style: normal;
          font-size: 16px;
          color: #3d454d;

          img {
            margin-left: 8px;
          }

          &:hover {
            /* background: rgba(39, 139, 255, 0.1); */
            color: #4260ff;
          }
        }

        &.all-tool {
          font-weight: 700;
          text-decoration: none;
          line-height: 70px;
          font-style: normal;
          color: #3d454d;
          font-size: 16px;

          &:hover {
            /* background: rgba(39, 139, 255, 0.1); */
            color: #4260ff !important;
            cursor: pointer;

            i {
              display: inline-block;
              transform: rotate(180deg);
              transition: transform 0.5s;
            }


            ~#all-tool-list {
              height: auto;
              padding: 32px 0;
              /* border-top: 1px solid #ccc; */
            }
          }

          img {
            margin-left: 8px;
          }
        }

        &.tool-coming {
          display: flex;

          &:hover {
            color: rgb(0, 22, 55);
          }

          p {
            opacity: 0.3;
            font-size: 16px;
            font-weight: 400;

            &:hover {
              color: rgb(0, 22, 55);
            }
          }

          img {
            margin-left: 4px;
          }
        }
      }
    }
  }

  header .second-header {
    position: relative;
    width: 100%;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px);
    height: 70px;

    .toolHover {
      /* background: rgba(39, 139, 255, 0.1); */
      color: #4260ff !important;
      cursor: pointer;

      i {
        display: inline-block;
        transform: rotate(180deg);
      }

      /* 
      img {
        content: url(../assets/nav/up.svg);
      } */
    }

    #convert-tool-list {
      background: #ffffff;
      padding: 40px 40px;
      z-index: -10;
      position: absolute;
      padding: 0;
      left: 310px;
      top: 70px;
      width: 460px !important;
      height: 0;
      transition: all 0.2s ease-in-out;
      box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      width: 500px;
      /* font-family: 'Be Vietnam Pro'; */
      font-style: normal;

      &:hover {
        height: auto;
        padding: 40px 0;
        /* border-top: 1px solid #ccc; */
      }

      .container {
        max-width: 460px;
        padding: 0 40px;
        margin: 0;

        ul {
          text-align: left;
          width: 170px;

          p {
            /* font-family: 'Be Vietnam Pro'; */
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #8fa0b2;
            padding: 0;
            margin: 0;
          }
        }
      }

      li {
        font-size: 16px;
        font-weight: 400;
        margin-top: 25px;
        line-height: 32px;
        display: flex;
        padding: 0;
        color: #3d454d;
        height: 32px;
        border-radius: 4px;

        img {
          margin: 4px 10px 0 4px;
          width: 24px;
          height: 24px;
        }

        span {
          /* font-family: 'Be Vietnam Pro'; */
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }

        &:hover {
          background-color: #ecefff;

          span {
            text-decoration: underline;
            /* color: #4260FF; */
          }
        }
      }
    }

    #all-tool-list {
      background: #ffffff;
      z-index: -10;
      position: absolute;
      padding: 0;
      left: 0;
      top: 70px;
      width: 100%;
      height: 0;
      transition: all 0.2s ease-in-out;
      box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      /* font-family: 'Be Vietnam Pro'; */
      font-style: normal;

      &:hover {
        height: auto;
        padding: 32px 0;
        /* border-top: 1px solid #ccc; */
      }

      .container {
        max-width: 1324px;
        padding: 0;

        ul {
          text-align: left;
          padding: 0;
          width: 190px;

          margin-right: 40px;

          p {
            /* font-family: 'Be Vietnam Pro'; */
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #8fa0b2;
            padding: 0;
            margin: 0;
          }
        }
      }

      li {
        font-size: 16px;
        font-weight: 400;
        margin-top: 25px;
        line-height: 32px;
        display: flex;
        padding: 0;
        color: #3d454d;
        height: 32px;
        border-radius: 4px;
        width: 190px;
        color: #3d454d;

        img {
          margin: 4px 10px 0 4px;
          width: 24px;
          height: 24px;
        }

        span {
          /* font-family: 'Be Vietnam Pro'; */
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }

        &:hover {
          /* color: #4260FF; */
          text-decoration: underline;
          background-color: #ecefff;

          span {
            text-decoration: underline;
            /* color: #4260FF; */
          }
        }
      }
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(-90deg);
    }

    to {
      transform: rotate(270deg);
    }
  }
</style>

<script>
  import { navFunction } from '../api/common'
  export default {
    data() {
      return {
        operation: ''
      }
    },
    methods: {
      goPurch() {
        window.open("https://cbs.pdnob.com/go?pid=5808&a=db")
      },
      download() {
        window.open('https://download.pdnob.com/go/pdnob-pdf_5808.exe')
      },
      checkAppState() {
        return this.$store.state.appState;
      },
      pathTo() {
        window.location = this.$t(`message.location`)
      },
      mouseEnter(len) {
        if (len == 'Convert') {
          document.querySelectorAll('.convert-tool')[0].classList.toggle('toolHover')
        } else if (len == 'AllTotal') {
          document.querySelectorAll('.all-tool')[0].classList.toggle('toolHover')
        }
      },
      mouseLeave(len) {
        if (len == 'Convert') {
          document.querySelectorAll('.convert-tool')[0].classList.remove('toolHover')
        } else if (len == 'AllTotal') {
          document.querySelectorAll('.all-tool')[0].classList.remove('toolHover')
        }
      },
      goPath(path, name, isHref) {
        //isHref判断是否是老版本的地址
        let _this = this
        document.querySelectorAll('#convert-tool-list')[0].style.display = 'none'
        document.querySelectorAll('#all-tool-list')[0].style.display = 'none'
        if (this.$store.state.isDown == 2) {
          // //console.log("nav2");
          this.$confirm(this.$t(`message.commonTip.alertTip[6].poptext`), {
            confirmButtonText: this.$t(`message.commonTip.alertTip[6].confirmButtonText`),
            cancelButtonText: this.$t(`message.commonTip.alertTip[6].cancelButtonText`),
            type: "none",
            closeOnClickModal: false
          })
            .then(() => {
              if (isHref) {
                window.location.href = path
              } else {
                window.location.href = _this.$store.state.locationUrl + path
              }

              document.documentElement.scrollTop = 0

            })
            .catch(() => {
              // //console.log("nav2Cancel");

            });
        } else if (this.$store.state.isDown == 1) {
          // //console.log("nav1");
          if (isHref) {
            window.location.href = path
          } else {
            window.location.href = _this.$store.state.locationUrl + path
          }

        } else {
          console.log("indexOf:", path, _this.checkAppState());
          // if (path.indexOf("convert") != -1 && _this.checkAppState() != "start") {
          if (_this.checkAppState() != "start" && _this.checkAppState() != "failConvert" && _this.checkAppState() != "failUpload") {
            console.log("------", _this.checkAppState());
            this.$confirm(this.$t(`message.commonTip.alertTip[5].poptext`), {
              confirmButtonText: this.$t(`message.commonTip.alertTip[5].confirmButtonText`),
              cancelButtonText: this.$t(`message.commonTip.alertTip[5].cancelButtonText`),
              type: "none",
              closeOnClickModal: false
            })
              .then(() => {
                if (isHref) {
                  window.location.href = path
                } else {
                  console.log("_this.$route.path3:");
                  window.location.href = _this.$store.state.locationUrl + path
                }
                document.documentElement.scrollTop = 0

              })
              .catch(() => {
              });
            setTimeout(() => {
              document.querySelectorAll('#convert-tool-list')[0].style.display = 'block'
              document.querySelectorAll('#all-tool-list')[0].style.display = 'block'
            }, 10)
          } else {
            if (isHref) {
              window.location.href = path
            } else {
              console.log("_this.$route.path4:");
              console.log(_this.$route);
              window.location.href = _this.$store.state.locationUrl + path
            }

          }
        }
        this.$gtag.event("navigation_choose", {
          event_category: "function",
          event_label: name,
        });
        setTimeout(() => {
          document.querySelectorAll('#convert-tool-list')[0].style.display = 'block'
          document.querySelectorAll('#all-tool-list')[0].style.display = 'block'
        }, 10)

      },
      //修改选中状态
      changeClass() {
        // console.log("this.$route.fullPath:", this.$route.fullPath);
        console.log("nav1111111111");
        if (this.$route.fullPath.indexOf('to_word') > 0) {
          document.querySelectorAll(".img6")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img6")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img6")[2].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('to_jpg') > 0) {
          document.querySelectorAll(".img7")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img7")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img7")[2].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('to_excel') > 0) {
          document.querySelectorAll(".img8")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img8")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img8")[2].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('to_ppt') > 0) {
          document.querySelectorAll(".img9")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img9")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img9")[2].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('to_text') > 0) {
          document.querySelectorAll(".img10")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img10")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img10")[2].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('to_html') > 0) {
          document.querySelectorAll(".img11")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img11")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img11")[2].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('from_other_word') > 0) {
          document.querySelectorAll(".img12")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img12")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img12")[2].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('from_other_jpg') > 0) {
          document.querySelectorAll(".img13")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img13")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img13")[2].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('from_other_excel') > 0) {
          document.querySelectorAll(".img14")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img14")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img14")[2].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('from_other_ppt') > 0) {
          document.querySelectorAll(".img15")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img15")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img15")[2].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('from_other_text') > 0) {
          document.querySelectorAll(".img16")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img16")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img16")[2].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('pdf-combiner') > 0) {
          console.log("---------：", document.querySelectorAll(".img1"));
          document.querySelectorAll(".img1")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img1")[1].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('compress-pdf') > 0) {
          document.querySelectorAll(".img5")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img5")[1].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('remove-watermark') > 0) {
          document.querySelectorAll(".img22")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img22")[1].style.background = "#ECEFFF"
        } else if (this.$route.fullPath.indexOf('convert') > 0) {
          document.querySelectorAll(".img6")[0].style.background = "#ECEFFF"
          document.querySelectorAll(".img6")[1].style.background = "#ECEFFF"
          document.querySelectorAll(".img6")[2].style.background = "#ECEFFF"
        }
      },

    },
    watch: {
      $route: {
        handler: function (to, from) {
          this.changeClass()
          let pageName
          let fromName
          console.log("watchto:", to);
          if (to.fullPath.indexOf('to_word') > 0) {
            pageName = "PDF_to_Word"
          } else if (to.fullPath.indexOf('to_jpg') > 0) {
            pageName = "PDF_to_JPG"
          } else if (to.fullPath.indexOf('to_excel') > 0) {
            pageName = "PDF_to_Excel"
          } else if (to.fullPath.indexOf('to_ppt') > 0) {
            pageName = "PDF_to_PPT"
          } else if (to.fullPath.indexOf('to_text') > 0) {
            pageName = "PDF_to_TXT"
          } else if (to.fullPath.indexOf('to_html') > 0) {
            pageName = "PDF_to_HTML"
          } else if (to.fullPath.indexOf('from_other_word') > 0) {
            pageName = "Word_to_PDF"
          } else if (to.fullPath.indexOf('from_other_jpg') > 0) {
            pageName = "Images_to_PDF"
          } else if (to.fullPath.indexOf('from_other_excel') > 0) {
            pageName = "Excel_to_PDF"
          } else if (to.fullPath.indexOf('from_other_ppt') > 0) {
            pageName = "PPT_to_PDF"
          } else if (to.fullPath.indexOf('from_other_text') > 0) {
            pageName = "TXT_to_PDF"
          } else if (to.fullPath.indexOf('convert') > 0) {
            pageName = "PDF_to_Word"
          } else if (to.fullPath.indexOf('pdf-combiner') > 0) {
            pageName = "Merge_PDF"
          } else if (to.fullPath.indexOf('compress-pdf') > 0) {
            pageName = "Compress_PDF"
          } else if (to.fullPath.indexOf('remove-watermark') > 0) {
            pageName = "Remove_Watermark"
          }
          if (sessionStorage.getItem('fromName')) {
            console.log("sessionStorage.getItem('fromName'):", sessionStorage.getItem('fromName'));
            fromName = sessionStorage.getItem('fromName')
            sessionStorage.setItem('fromName', pageName.toLowerCase().replace(/_/g, ''))
          } else {
            fromName = pageName.toLowerCase().replace(/_/g, '')
            sessionStorage.setItem('fromName', fromName)
            console.log("fromName:", fromName);
          }
          this.$gtag.event("page_views", {
            event_category: pageName,
            event_label: fromName,
          });
          console.log('---------------------:', pageName, fromName);
        },
        deep: true
      },
    },
    mounted() {
      this.operation = sessionStorage.getItem('getOS');
    },
  }
</script>