export default {
	message: {
		location:"https://www.niuxuezhang.cn/online-edit-pdf.html",
		new:"NEW",
		new1:"即将推出",
		ogtitle:"Process PDF file online and free, please browse the professional website: https://pdnob.passfab.com/ via PassFab.com",
		init:{
			text:["选择要使用的工具","选择要处理的文件","设置处理的模式","开始"]
		},
		logo:"http://www.passfab.com/images/pdf-convert/pdf-logo.svg",
		wating:"正在加载页面，请稍候",
		popus:"加载所有页面后有效",
		Navigation: {
			pdf: {
				title: "PDF工具集",
				info: ["PDF转换成Word", "PDF转换成Excel", "PDF转换成PPT", "PDF转换成TEXT", "PDF转换成JPG", "PDF转换成HTML",
					"Word转换成PDF", "Excel转换成PDF", "PPT转换成PDF", "TEXT转换成PDF", "图片转换成PDF", "HTML转换成PDF",
					"压缩PDF", "合并PDF", "分割PDF", "PDF加密", "PDF页面删除", "旋转PDF",
					"添加水印", "PDF图片提取", "PDF页面提取"
				]
			},
			img: {
				title: "图片工具集",
				info: []
			},
			Other: {
				title: "其他工具集",
				info: []
			},
		},
		FunList: ["PDF转换器", "分割PDF", "合并PDF", "添加水印", "压缩PDF", "旋转PDF",
			"PDF加密", "PDF页面删除", "PDF图片提取", "PDF页面提取"
		],
		FunOperat: {
			splitPDF: {
				Operat: "分割PDF",
				Operat1: "提取页面",
				Split: {
					text: ["分割", "在每一页后面分割", "自定义范围"],
					Range: ["范围", "添加范围", "合并成一个PDF","到"]
				},
				Extract: {
					text: ["提取", "提取所有页面", "从所选页面中提取"],
					Page: ["页码", "输入页码", "例如：1,2-3,6-8"]
				},
				tip: "您最多可以选择5个范围将PDF拆分为5个PDF。例如，在范围1中设置第1页到第4页，即，将此PDF文件的第1页到第4页拆分为新的PDF。"
			},
			mergePDF: {
				Operat: "合并PDF",
				add: {
					text: ["文件", "添加文件", "拖动文件改变顺序。","你不能添加超过4个文件。"]
				}
			},
			addWater: {
				Operat: "应用",
				text: {
					text: ["文本", "输入文本", "尺寸", "透明度", "位置", "旋转", "平铺显示"]
				},
				img: {
					text: ["图片", "添加图片"]
				}
			},
			compressPDF: {
				Operat: "压缩PDF",
				Compression: {
					text: ["压缩模式", "清晰优先", "缩小优先"]
				}
			},
			rotatePDF: {
				Operat: "旋转PDF",
				mess:"正在加载图片，请稍后。",
				Rotate: {
					text: ["旋转所有", "左", "右"]
				}
			},
			protectPDF: {
				Operat: "加密PDF",
				protect: {
					text: ["密码", "输入你的密码", "重复密码", "重复你的密码"],
					tip:["请填写两次密码以保护PDF文件。","密码不能为空。","请输入与上述相同的密码。"]
				}
			},
			delatePDF: {
				Operat: "删除页面",
			},
			extractIMG: {
				Operat: "提取图片",
				img: {
					text: ["图片格式", "提取模式", "从所有页面提取", "从所选页面中提取",
						"页码", "输入页码", "例如：1,2-3,6-8"
					]
				}
			}

		},
		selectFile: ["选择文件", "或者拖拽文件到这里", "把HTML转换成PDF","输入 URL 以添加 HTML文件。"],
		SelectFun: {
			title: "选择转换功能",
			Fun: ["转换成Word", "转换成JPG", "转换成Excel", "转换成TEXT", "转换成PPT",
				"转换成HTML", "转换成PDF"
			],
			operation: []
		},
		fileTopdf: {
			button: "转换成PDF",
			JpgToPdf:{
				text: ["图片", "添加图片","你不能添加超过4张图片。","拖动图片改变顺序。","转换成一个PDF"]
			}
		},
		PdfTofile: {
			button: ["转换成Excel", "转换成HTML", "转换成图片", "转换成PPT", "转换成TEXT",
				"转换成Word"
			],
			htmlToPDF: [
				"正在进入预览界面，请耐心等待。",
				"网站地址",
				"页面尺寸",
				"不能为空。",
				"您输入的URL无效。请重新输入正确的URL。"
			],
			pdftoimg: "去提取图像"
		},
		convertTip: ["正在转换文件，请耐心等待。",
			"正在分割文件，请耐心等待。",
			"正在合并文件，请耐心等待。",
			"正在为文件添加水印，请耐心等待。",
			"正在压缩文件，请耐心等待。",
			"正在旋转页面，请耐心等待。",
			"正在加密文件，请耐心等待。",
			"正在删除文件中的页面，请耐心等待。",
			"正在提取文件中的图片，请耐心等待。",
			"正在提取文件中的页面，请耐心等待。"
		],
		successText: ["您的文件已经被转换成Word文件。",
			"您的文件已经被转换成Excel文件。",
			"您的文件已经被转换成PPT文件。",
			"您的文件已经被转换成TEXT文件。",
			"您的文件已经被转换成PDF文件。",
			"您的文件已经被转换成JPG文件。",
			"您的文件已经被转换成HTML文件。",
			"已成功分割您的文件。",
			"已成功合并您的文件。",
			"已成功为您的文件添加水印。",
			"已成功压缩您的文件。",
			"已成功旋转您的文件页面。",
			"已成功加密您的文件。",
			"已成功将您的文件删除页面。",
			"已成功提取您的文件图片。",
			"已成功提取您的文件页面。"
		],
		convertConfirm: {
			error: "转换失败！",
			title: "您想再次尝试转换吗？",
			confirmButtonText: "再试一次",
			cancelButtonText: "取消",
		},
		commonTip: {
			pop: [{
				"popConverFailTit": "转换失败！",
				"popConverFailText": "您想再次尝试转换吗？",
			}, {
				"popConverFailTit": "合并失败！",
				"popConverFailText": "您想再次尝试合并吗？",
			}, {
				"popConverFailTit": "分割失败！",
				"popConverFailText": "您想再次尝试分割吗？",
			}, {
				"popConverFailTit": "加密失败！",
				"popConverFailText": "您想再次尝试加密吗？",
			}, {
				"popConverFailTit": "压缩失败！",
				"popConverFailText": "您想再次尝试压缩吗？",
			}, {
				"popConverFailTit": "删除页面失败！",
				"popConverFailText": "您想再次尝试删除页面吗？",
			}, {
				"popConverFailTit": "旋转页面失败！",
				"popConverFailText": "您想再次尝试旋转页面吗？",
			}, {
				"popConverFailTit": "提取页面失败！",
				"popConverFailText": "您想再次尝试提取页面吗？",
			}, {
				"popConverFailTit": "提取图片失败！",
				"popConverFailText": "您想再次尝试提取图片吗？",
			}, {
				"popConverFailTit": "增加水印失败！",
				"popConverFailText": "您想再次尝试添加水印吗？",
			}],
			alertTip: [{
					poptit: "只能转换一个文件,",
					poptext: "请选择一个文件。",
					confirmButtonText: "好的"
					
				}, {
					poptit: "文件格式不支持！",
					poptext: "此功能不支持此类型的文件。请上传相应类型的文件。",
					confirmButtonText: "好的"
				}, {
					poptit: "您上传的文件大小超出限制，无法上传！",
					poptext: "上传的文件大小最大为10 MB。",
					confirmButtonText: "好的"
				}, {
					poptit: "无法读取您的文件,",
					poptext: "请检查您的文件是否已损坏/加密。",
					confirmButtonText: "好的"
				}, {
					poptit: "下载失败！",
					poptext: "你想要再次尝试下载吗？",
					confirmButtonText: '再试一次',
					cancelButtonText: '取消',
				}, {
					poptit: "图片格式不支持！",
					poptext: "此功能不支持此格式的图片。请上传JPG，PNG或BMP图片。",
					confirmButtonText: "好的"
				}, {
					poptit: "下载失败！",
					poptext: "一个多小时文件已过期，请重试",
					confirmButtonText: '再试一次',
					cancelButtonText: '取消',
				}, {
					poptit: "转换失败",
					poptext: "某些原因导致文件转换失败,",
					poptext1: "是否要再次尝试转换？",
					cancelButtonText: "好的",
					confirmButtonText: "取消"
				}, {
					poptext: "离开此页面，保留文件纪录，但当前的操作将不保留。",
					confirmButtonText: "好的",
					cancelButtonText: "取消"
				}, {
					poptext: "离开此页面，文件记录将不保留。",
					confirmButtonText: "好的",
					cancelButtonText: "取消"
				}, {
					poptit: "您的文件还没下载，",
					poptext: "确定要离开此页面吗？",
					confirmButtonText: "好的",
					cancelButtonText: "取消"
				}, {
					poptext: "服务器异常,您的文件无法上传！",
					confirmButtonText: "再试一次",
					cancelButtonText: "取消"
				}, {
					poptit: "预览失败！",
					poptext: "找不到您尝试访问的网页或该网页存在连接问题。请检查URL是否正确写入，然后重试。",
					confirmButtonText: "好的",
				},{
					poptit: "转换失败！",
					poptext: "您想再次尝试转换吗？",
					confirmButtonText: "再试一次",
					cancelButtonText: "取消",
				}, {
					poptit: "上传失败",
					poptext: "一些问题导致文件上载失败,",
					poptext1: "你想再上传一次吗？",
					cancelButtonText: "再试一次",
					confirmButtonText: "取消"
				}
				


			]
		},
		downloadPag: {
			tip: "我们将为您保存1个小时，请尽快下载，感谢您的使用。",
		},
		download: "下载",
		Downloading: "下载中",
		Restart: "重新开始",
		back: "返回首页",
		funText:{
			title:"什么是Passfab PDNob？",
			h3:["卓越的免费PDF工具集","出色的文档处理质量","简单可靠的工具","适用所有操作系统","自动删除","贴心服务"],
			p:["功能十分强大，提供12种PDF格式转换，还有分割、合并等文件处理的功能。",
				"所有处理过的文档都是高质量的，并且没有水印。",
				"用户友好的界面，并使用HTTPS（超文本传输协议安全）技术来保护文档在处理过程中的安全。",
				"软件在浏览器高效运行，支持所有操作系统，Chrome、Firefox 和 Opera 等多种浏览器。",
				"一小时后，我们的服务器将自动永久删除您的所有文件。",
				"在文件处理的过程中遇到疑惑，可到帮助中心寻找教程或者联系客服。"

		]
		},
		stars:{
			text:["请单击星星给出您的评分","票数","分享给您的朋友","提交"]
		}



	},
}
