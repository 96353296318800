<template lang="">
	<div class="footer">
		<div class="container pt-lg-5 pt-3">
			<div class="row justify-content-start">
				<el-col :span="6" class="col-lg-3">
					<p class="title mb-3 active">Company</p>
					<ul class="list-unstyled ps-0">
						<li><a href="https://www.pdnob.com/about-us.html">About us</a></li>
						<li><a href="https://www.pdnob.com/privacy-policy.html">Privacy</a></li>
						<li><a href="https://www.pdnob.com/terms-of-service.html">Terms & Conditions</a></li>
						<li><a href="https://www.pdnob.com/cookies-privacy.html">Cookies privacy</a></li>

					</ul>
				</el-col>
				<el-col :span="6" class="col-lg-3 border-black">
					<p class="title  mb-3 active">Support</p>
					<ul class="list-unstyled  ps-0">
						<li><a href="https://www.pdnob.com/contact-us.html">Contact us</a></li>
						<li><a href="https://www.pdnob.com/support/order-faqs.html">Order FAQs</a></li>
						<li><a href="https://www.pdnob.com/support/retrieve-registration-code.html">Registration FAQs</a></li>
						<li><a href="https://www.pdnob.com/how-to.html">How-to Tutorials</a></li>
					</ul>
				</el-col>
				<el-col :span="10" class="col-lg-5 border-black mb-4 mb-sm-0">
					<p class="title expanded  mb-4">Subscribe us</p>
					<!-- <el-form class="mb-4" data-toggle="rn-ui-form"> -->
					<div class="mb-4">
						<div class="input-group  p-2" style="max-width: 305px; border-radius:10px">
							<input type="email" name="email" v-model="subEmail"
								class="form-control bg-transparent border-0 rounded-0 text-white  p-0 ps-1"
								placeholder="Please enter email" required="true" />
							<input type="hidden" name="cid" value="202" />
							<el-popover placement="top" title="标题" width="200" trigger="click" content="Thank you for subscribing">
								<button class="btn btn-primary py-1 small px-3 " style="font-size:14px; border-radius:6px"
									@click="searchChange">
									Send
								</button>
							</el-popover>
							<!-- <button class="btn btn-primary py-1 small px-3 " style="font-size:14px; border-radius:6px"
								@click="searchChange">
								Send
							</button> -->
						</div>
						<p class="d-none" data-successtips="">Thank you for subscribing</p>
						<p class="d-none" data-failtips=""></p>
						<!-- <div class="tooltip fade show bs-tooltip-top" role="tooltip" id="tooltip554732"
							style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(1218px, 5837.75px);"
							data-popper-placement="top">
							<div class="tooltip-arrow" style="position: absolute; left: 0px; transform: translate(89px, 0px);"></div>
							<div class="tooltip-inner">Thank you for subscribing</div>
						</div> -->
					</div>
					<p class="title expanded mb-4">Follow Us</p>
					<div class="d-flex flex-wrap mb-lg-4">
						<div class="pe-3">
							<a href="https://www.youtube.com/channel/UCh-2UkwRqv505_-w-ZY225A">
								<svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<path opacity="0.6"
										d="M15 0C6.71562 0 0 6.71562 0 15C0 23.2844 6.71562 30 15 30C23.2844 30 30 23.2844 30 15C30 6.71562 23.2844 0 15 0Z"
										fill="currentColor" />
									<path
										d="M17.0672 14.7391L13.5578 13.1016C13.2516 12.9594 13 13.1187 13 13.4578V16.5422C13 16.8813 13.2516 17.0406 13.5578 16.8984L17.0656 15.2609C17.3734 15.1172 17.3734 14.8828 17.0672 14.7391ZM15 21C7.32188 21 7 20.4016 7 15C7 9.59844 7.32188 9 15 9C22.6781 9 23 9.59844 23 15C23 20.4016 22.6781 21 15 21Z"
										fill="#47495F" />
								</svg>
							</a>
						</div>
						<div class="pe-3">
							<a href="https://www.reddit.com/user/PDNob">
								<svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<path opacity="0.6"
										d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
										fill="currentColor" />
									<path
										d="M24.9766 15.1424C24.9766 13.9264 23.9933 12.9607 22.7949 12.9607C22.2288 12.9599 21.6843 13.1778 21.275 13.5688C19.7729 12.4957 17.7162 11.7984 15.4273 11.709L16.4286 7.02375L19.6832 7.72102C19.7192 8.54367 20.3985 9.20543 21.2392 9.20543C22.0975 9.20543 22.7949 8.50816 22.7949 7.64941C22.7949 6.79113 22.0976 6.09375 21.2392 6.09375C20.6313 6.09375 20.0946 6.45141 19.8442 6.97008L16.2142 6.20109C16.1069 6.18305 15.9996 6.20109 15.928 6.25477C15.8385 6.30844 15.785 6.39773 15.7673 6.50508L14.6582 11.7267C12.3336 11.7984 10.2412 12.4957 8.72125 13.5867C8.31187 13.1957 7.76731 12.9779 7.20121 12.9788C5.98516 12.9788 5.01953 13.9621 5.01953 15.1604C5.01953 16.0545 5.5559 16.8054 6.30719 17.1455C6.2707 17.3641 6.25275 17.5854 6.25352 17.8071C6.25352 21.1687 10.1699 23.9051 14.9982 23.9051C19.8265 23.9051 23.743 21.1868 23.743 17.8071C23.7428 17.5855 23.7249 17.3642 23.6893 17.1455C24.4402 16.8054 24.9766 16.0364 24.9766 15.1424ZM9.99086 16.698C9.99086 15.8398 10.6881 15.1424 11.5469 15.1424C12.4052 15.1424 13.1026 15.8396 13.1026 16.698C13.1026 17.5564 12.4053 18.2541 11.5469 18.2541C10.6883 18.2716 9.99086 17.5564 9.99086 16.698ZM18.6999 20.829C17.6269 21.9021 15.5882 21.9737 14.9982 21.9737C14.3902 21.9737 12.3516 21.8841 11.2962 20.829C11.1356 20.6681 11.1356 20.4177 11.2962 20.2568C11.4572 20.0961 11.7076 20.0961 11.8686 20.2568C12.5483 20.9365 13.9789 21.1687 14.9982 21.1687C16.0176 21.1687 17.4659 20.9364 18.1276 20.2568C18.2886 20.0961 18.5389 20.0961 18.6999 20.2568C18.8429 20.4177 18.8429 20.6681 18.6999 20.829ZM18.4136 18.2718C17.5552 18.2718 16.858 17.5745 16.858 16.7161C16.858 15.8577 17.5552 15.1604 18.4136 15.1604C19.2722 15.1604 19.9695 15.8577 19.9695 16.7161C19.9695 17.5563 19.2722 18.2718 18.4136 18.2718Z"
										fill="#47495F" />
								</svg>

							</a>
						</div>

						<div class="pe-3">
							<a href="https://www.facebook.com/pdnobofficial/">
								<svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<g clip-path="url(#clip0_438_1604)">
										<path opacity="0.6"
											d="M30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15Z"
											fill="currentColor" />
										<path
											d="M12 19V30.0002H16V19H20V15.0002H16V12.0839C16 10.9165 16.4163 10.0002 18.25 10.0002H20V6.00046C20 6.00046 18.9163 6 17.8337 6C14.4163 6 12 7.91648 12 11.6665V15.0002H8V19H12Z"
											fill="#47495F" />
									</g>
									<defs>
										<clipPath id="clip0_438_1604">
											<rect width="30" height="30" fill="currentColor" />
										</clipPath>
									</defs>
								</svg>

							</a>
						</div>
					</div>
				</el-col>
			</div>
		</div>
		<div class="mx-auto container mb-4">
			<hr class="bg-white m-0">
		</div>
		<div class="container pb-5">
			<div class="d-flex flex-column flex-lg-row text-center justify-content-lg-between">
				<p class="small mb-0 text-start" style="color: #F5F7FD;opacity: .5;">Copyright © 2010-2022 PDNob All Rights
					Reserved.</p>
			</div>
		</div>
	</div>

	<span data-toggle="backToTop" style="z-index:10;" class="backToTop show" @click="backToTop">
		<svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill="#000" fill-opacity=".7" stroke="#fff" d="M.5.5h39v39H.5z"></path>
			<path d="M13 19l7-7 7 7M20 13v16" stroke="#fff" stroke-width="3" stroke-linecap="square"></path>
		</svg>
	</span>
</template>
<script>
	export default {
		data() {
			return {
				footType: '',
				subEmail: "",
				visible: false
			}
		},
		methods: {
			scrollDs() {
				if (document.documentElement.scrollTop >= 500) {
					document.querySelector('.backToTop').classList.add('active')
					document.querySelector('.backToTop').classList.remove('show')
				} else {
					document.querySelector('.backToTop').classList.add('show')
					document.querySelector('.backToTop').classList.remove('active')
				}
			},
			searchChange() {
				//console.log(111)
				const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
				let str = this.subEmail;
				if (reg.test(str)) {
					// 这里是邮箱验证成功的代码
					//console.log("成功");
					this.$http
						.post('https://apis.tenorshare.com/v1/activity/subscribe', {
							cid: '202',
							email: this.subEmail,
						}).then((res) => {
							//console.log("res:", res);
							if (res.status === 200) {
								document.querySelector(".d-none").classList.remove("d-none")
								// this.$message.success("Thank you for your Subscribe");
								this.$gtag.event("send", {
									event_category: "subscription",
									event_label: "null"
								});

							}
							this.subEmail = ""
						}).catch((err) => {
							//console.log("err:", err);
							this.subEmail = ""

						})
					// subscribe({
					// 	e_mail: this.email,
					// 	state: this.state,
					// 	notes: this.notes
					// }).then(res => {
					// 	//console.log(res);
					// 	if (res.data.code === 20000) {
					// 		this.$message.success("Subscribe to the success");
					// 	} else {
					// 		this.$message.warning(res.data.message);
					// 		return false;
					// 	}
					// });
				} else {
					this.visible = true
				}
			},
			backToTop() {
				//console.log("111");
				document.documentElement.scrollTop = 0
			},
		},
		mounted() {
			window.addEventListener('scroll', this.scrollDs, true)
			this.$nextTick(() => {
				this.footType = this.$store.state.local
			})
		},
	}
</script>
<style scoped>
	/* ::v-deep .el-input__inner {
		background: rgba(255, 255, 255, 0.1);
		border: 1px solid rgba(255, 255, 255, 0.05);
		border-radius: 10px !important;
		font-size: 14px;
		color: #fff;
	} */

	.container {
		max-width: 1224px;
	}

	.el-button {
		background-color: #4260ff !important;
		border-color: #4260ff !important;
		font-size: 16px !important;
		font-weight: 700 !important;
		position: relative;
		height: 40px !important;
	}

	.footer {
		text-align: start;
		/* background-color: #47495f !important; */
	}

	.footer .title:not(.expanded).active::after {
		display: none;
	}

	.show {
		display: none !important;
	}

	.active {
		display: block !important;
	}




	/* .btn-send {
		background-color: rgb(66, 96, 255);
		font-size: 14px;
		border-radius: 6px;
		z-index: 100;
		height: 30px;
		width: 50px;
		line-height: 30px;
		text-align: center;
		margin: 4px 0 0 -70px;
	} */
</style>