<template>
  <div class="Register">
    <div class="section">
      <div class="title">
        <img class="titleImg" src="../../../assets/login/logo.svg" alt="">
        <p class="titlelogin">Create a PDNob ID</p>
        <p class="titlegoR">
          Back to <span style="color:#5452F6;cursor:pointer" @click="goRegister">login</span>
        </p>
      </div>
      <el-form class="apply-form first-form" :model="formData" :rules="rule" ref="registerFormRef"
        style="margin-bottom: 8px;">
        <el-form-item label prop="UserEmail">
          <div class="left-style backEmi" autocomplete="auto" />
          <el-input placeholder="E-mail" v-model="formData.UserEmail" @blur="UserEmailTrim" @input="checkEmail"
            autocomplete="new-text"></el-input>
        </el-form-item>

        <el-form-item label prop="EmailCode">
          <div class="verificationBox">
            <!-- <div class="left-style backProtect" autocomplete="auto" /> -->
            <el-input class="verificationInput" placeholder="Verification code" v-model="formData.EmailCode"
              @input="checkEmailCode" @blur="UserEmailCodeTrim"></el-input>
            <div v-if="formData.isSend" :disabled="formData.disableCode"
              :class=" formData.isSend ? 'verificationButton': 'unverificationButton'" @click="setEmailcode">Get Code
            </div>
            <div v-else :class="formData.isSend? 'verificationButton': 'unverificationButton'" @click="setEmailcode">
              {{ formData.EmailCodeButtonContent }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label prop="UserPassword" class="pass">
          <div class="left-style backPass" autocomplete="auto" />
          <el-input style="position: relative" placeholder="Password" v-model="formData.UserPassword"
            :type="formData.isShowPassword ? 'text' : 'password'" @input="UserPasswordTrim" @blur="checkPassword"
            autocomplete="new-password">
          </el-input>
          <div class="eye-style" :class="[formData.isShowPassword ? 'eyes' : 'uneye']" autocomplete="auto"
            @click="formData.isShowPassword = !formData.isShowPassword" />
        </el-form-item>
      </el-form>

      <!-- <el-button :class="formData.isOk ? 'signUpButton' : 'unsignUpButton'" :disabled="formData.disable">
        Log in
      </el-button> -->
      <div v-if="formData.isSignUp" :class="formData.isOk ? 'signUpButton' : 'unsignUpButton'" @click="setRegister"
        :disabled="formData.disable">
        Sign up
      </div>
      <div v-else :class="formData.isOk ? 'signUpButton' : 'unsignUpButton'">
        <div class="custom-loading">
          <svg class="circular" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
              d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.45459 8.00014C1.45459 11.6151 4.38509 14.5456 8.00004 14.5456C11.615 14.5456 14.5455 11.6151 14.5455 8.00014C14.5455 4.38519 11.615 1.45469 8.00004 1.45469C4.38509 1.45469 1.45459 4.38519 1.45459 8.00014Z"
              fill="white" />
            <path
              d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16V14.5455C4.38505 14.5455 1.45455 11.615 1.45455 8C1.45455 4.38505 4.38505 1.45455 8 1.45455V0Z"
              fill="white" />
          </svg>
          Sign up
        </div>
      </div>
      <div class="goRulesIns">
        By signing up, you agree to
        <span class="goRulesButton" @click="goTermsOfService">
          the Terms of Service</span>
        and
        <span class="goRulesButton" @click="goPrivacyPolicy">
          Privacy Policy</span>
      </div>
      <!-- <div class="goRules">
          <el-checkbox class="isAgree" v-model="formData.isAgree" size="small"></el-checkbox>
          123
          <span class="goRulesButton" @click="goTermsOfService">
            123</span>123
          <span class="goRulesButton" @click="goPrivacyPolicy">
            123</span>
        </div> -->
    </div>
  </div>
</template>

<script>
  import tools from "@/utils/tools.js";
  import { gaEvent } from "@/utils/gaEvent";
  export default {
    name: "Register",
    data() {
      const validateUserNameASCII = (rule, value, callback) => {
        var re = /[^a-zA-Z0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
        this.formData.UserEmail = value.replace(re, "")
        callback()
      }
      const validateUserPasswordASCII = (rule, value, callback) => {
        var re = /[^a-zA-Z0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
        this.formData.UserPassword = value.replace(re, "")
        callback()
      }
      const validateUserEmailCodeASCII = (rule, value, callback) => {
        var re = /[^0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
        this.formData.EmailCode = value.replace(re, "")
        callback()
      }
      const UserPasswordEasy = (rule, value, callback) => {
        if (value) {
          var emailFirst = this.formData.UserEmail.split('@')[0];
          var emailFirstReverse = emailFirst.split('').reverse().join('');

          var valueReverse = value.split('').reverse().join('');
          if (valueReverse == this.formData.UserEmail || value == this.formData.UserEmail || value == emailFirst || value == emailFirstReverse) {
            callback(new Error('Password cannot be username, or reversed username'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      }
      return {
        senMsg: 1,
        formData: {
          UserEmail: "",
          UserPassword: "",
          EmailCode: "",
          isShowPassword: false,
          isOk: false,
          EmailCodeButtonContent: "",
          EmailCodeButtonTime: 60,
          isSend: true,
          isAgree: true, // 保密协议是否勾选
          EmailTempToken: "",
          inputEmail: false,
          inputPassword: false,
          inputEmailCode: false,
          // btnText: "Sign up",
          isSignUp: true,
          disable: false,
          disableCode: false,
        },
        rule: {
          UserEmail: [
            { validator: validateUserNameASCII, trigger: "change" },
            { required: true, message: 'Email address cannot be empty', trigger: 'blur' },
            {
              required: true,
              // pattern:/^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Email is invalid',
              trigger: 'blur',
            },
            {
              required: true,
              pattern: /^\S{1,100}$/,
              message: 'Email is invalid', trigger: 'blur'
            }
          ],
          UserPassword: [
            { validator: UserPasswordEasy, trigger: "blur" },

            { validator: validateUserPasswordASCII, trigger: "change" },

            { required: true, message: 'Password cannot be empty', trigger: 'blur' },
            {
              required: true,
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#_~?&^]{8,20}$/,
              message: 'Password must be 8-20 characters and a combination of letters and numbers.',
              trigger: 'blur',
            },
          ],
          EmailCode: [
            { required: true, message: 'Code cannot be empty', trigger: 'blur' },

            { validator: validateUserEmailCodeASCII, trigger: "change" },

            {
              required: true,
              pattern: /^\d{6}$/,
              message: 'Please enter a valid code',
              trigger: 'blur',
            }
          ]
        },
      }
    },
    methods: {
      // 验证
      UserEmailTrim() {
        console.log("-=-=-=-");
        this.formData.UserEmail = tools.LTrim(tools.RTrim(this.formData.UserEmail))
      },
      UserPasswordTrim() {
        this.formData.UserPassword = tools.LTrim(tools.RTrim(this.formData.UserPassword))
      },
      UserEmailCodeTrim() {
        this.formData.EmailCode = tools.LTrim(tools.RTrim(this.formData.EmailCode))
      },
      // const formRef: any = unref(registerFormRef);
      checkEmail() {
        this.$refs["registerFormRef"].validateField(["UserEmail"], errMsg => {
          if (errMsg) {
            this.input1 = false
            return false;
          } else {
            this.input1 = true
            return true;
          }
        });
      },
      checkPassword() {
        console.log("校验");
        this.$refs["registerFormRef"].validateField(["UserPassword"], errMsg => {
          console.log("校验111：", errMsg);

          if (errMsg) {
            console.log("11111111111");
            this.input2 = false
            return false;
          } else {
            console.log("22222222222222222");
            this.input2 = true
            return true;
          }
        });
      },

      checkEmailCode() {
        this.$refs["registerFormRef"].validateField(["EmailCode"], errMsg => {
          if (errMsg) {
            this.input3 = false
            return false;
          } else {
            this.input3 = true
            return true;
          }
        });
      },

      // 验证码
      setEmailcode() {
        console.log("发送验证吗");
        this.$refs["registerFormRef"].validateField(["UserEmail"], errMsg => {
          if (errMsg) {
            return false;
          } else {
            if (!this.formData.isSend) {
              return;
            }
            this.$http
              .post('/app/user/send-code', {
                token: this.$store.state.pdfToken,
                type: 0,
                email: this.formData.UserEmail
              }).then(res => {
                console.log("res:", res);
                if (res.code == 200) {
                  console.log("res1111:", res);
                  this.$message.success("Verification code has been sent to your email");
                  let clock = window.setInterval(() => {
                    if (this.formData.EmailCodeButtonTime < 1) {
                      this.formData.EmailCodeButtonContent = 'Send code';
                      this.formData.EmailCodeButtonTime = 60;
                      this.formData.isSend = true;
                      clearInterval(clock);
                    } else {
                      console.log("60s倒计时:", this.formData.EmailCodeButtonTime);
                      this.formData.EmailCodeButtonContent = `${this.formData.EmailCodeButtonTime--}s`;
                      this.formData.isSend = false
                    }
                  }, 1000)

                  console.log("邮箱验证码发送成功", res);
                  this.formData.EmailTempToken = res.data.email_token;
                }

              }).catch(rej => {
                this.$message.warning(rej.response.data.msg)
                console.log("邮箱验证码发送失败", rej);
              })
          }
        });
      },

      setRegister() {
        gaEvent("click_login", {
          event_category: "Login_Register",
          event_label: this.$store.state.currentPage,
        });
        this.checkPassword()
        // console.log("aaaaaaaaaaaaaaaa:", a);
        if (!this.input2) return
        if (!this.formData.isOk) {
          console.log("开始注册1111");
          return;
        }
        console.log("开始注册");
        if (!(this.formData.UserEmail && this.formData.UserPassword && this.formData.EmailCode)) {
          console.log("开始注册123:", this.formData);
          return;
          // } else if (this.formData.UserEmail && this.formData.UserPassword && this.formData.EmailCode && !data.token) {
        }
        else if (this.formData.UserEmail && this.formData.UserPassword && this.formData.EmailCode && !this.formData.EmailTempToken) {
          this.$message.error("Please get the verification code first.");
          gaEvent("login_fail", {
            event_category: "Login_Register",
            event_label: "410001",
          });
          console.log("开始注册456：", this.formData);
          return;
        }
        if (this.formData.disable === false) {
          console.log("开始注册789:", this.formData);
          this.formData.disable = true;
          // formData.btnText = "Creating  Account…";
          this.formData.isSignUp = false;
          this.$http.post('/app/user/register', {
            email: this.formData.UserEmail,
            password: this.formData.UserPassword,
            token: this.formData.EmailTempToken,
            code: this.formData.EmailCode,
          })
            .then((res) => {

              console.log("注册成功", res);
              if (res.code == 200) {
                this.$message({
                  message: "Registered successfully!",
                  type: "success",
                });
                console.log("res.code:", res.code);
                gaEvent("login_success", {
                  event_category: "Login_Register",
                  event_label: this.$store.state.currentPage,
                });
                this.$http.post('/app/user/login', {
                  email: this.formData.UserEmail,
                  password: this.formData.UserPassword,
                  device_id: localStorage.getItem('deviceId'),
                  device_name: localStorage.getItem('pcusername'),
                })
                  .then((res) => {
                    if (res.code == "200") {
                      localStorage.setItem("token", res.data.token);
                      // 登录密钥
                      localStorage.setItem("authorization", res.data.token);
                      localStorage.setItem(
                        "userInfo",
                        JSON.stringify(res.data)
                      );
                      this.$store.commit("changeToken", res.data.token);
                      this.$emit("loginResponseSuccess", res.data, 'register');
                    } else {
                      this.$emit("registerError", "自动登录失败");
                    }

                  })
                  .catch((err) => {
                    console.log("登录失败", err);
                    this.$emit("registerError", "自动登录失败");
                    this.formData.disable = false;
                    // formData.btnText = "Sign up";
                    this.formData.isSignUp = true;
                    gaEvent("login_fail", {
                      event_category: "Login_Register",
                      event_label: "500",
                    });
                  });
              } else {
                this.$emit("registerError");
                this.$messge.error(res.data.message);
                this.formData.disable = false;
                this.formData.isSignUp = true;
              }
            })
            .catch((err) => {
              // this.$emit("registerError");
              gaEvent("login_fail", {
                event_category: "Login_Register",
                event_label: err.response.data.code,
              });
              this.$message.error(err.response.data.msg);
              console.log("注册失败", err);
              this.formData.disable = false;
              // formData.btnText = "Sign up";
              this.formData.isSignUp = true;
            });
        }
      },
      goTermsOfService() {
        window.open("https://cbs.pdnob.com/go?pid=5808&a=tc");
      },

      goPrivacyPolicy() {
        window.open("https://cbs.pdnob.com/go?pid=5808&a=p");
      },

      // 登录去注册
      goRegister() {
        this.$store.commit("setModalStatus", "1");
      },

    },

    watch: {
      'formData.UserEmail': function (val) {
        if (this.formData.UserEmail && this.formData.UserPassword && this.formData.EmailCode) {
          this.formData.isOk = true
          console.log("UserEmail1:", this.formData.isOk);
        } else {
          this.formData.isOk = false
          console.log("UserEmail2:", this.formData.isOk);

        }
      },
      'formData.UserPassword': function (val) {
        if (this.formData.UserEmail && this.formData.UserPassword && this.formData.EmailCode) {
          this.formData.isOk = true
          console.log("UserPassword1:", this.formData.isOk);
        } else {
          this.formData.isOk = false
          console.log("UserPassword2:", this.formData.isOk);
        }
      },
      'formData.EmailCode': function (val) {
        if (this.formData.UserEmail && this.formData.UserPassword && this.formData.EmailCode) {
          this.formData.isOk = true
          console.log("EmailCode1:", this.formData.isOk);
        } else {
          this.formData.isOk = false
          console.log("EmailCode2:", this.formData.isOk);
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .backProtect {
    z-index: 999;
    border: none;
    background: url("../../../assets/login/protect.svg") no-repeat;
    height: 20px;
    width: 21px;
    margin-left: 1.375rem;
  }

  .left-style {
    position: absolute;
    cursor: pointer;
  }

  .backEmi {
    z-index: 999;
    border: none;
    height: 20px;
    width: 21px;
    margin-left: 22px;
  }

  .backPass {
    z-index: 999;
    border: none;
    height: 20px;
    width: 21px;
    margin-left: 22px;
  }

  /* 已修改 */
  :deep(.el-input__wrapper) {
    border-radius: 61px !important;
    padding: 0;
  }

  .Register {
    z-index: 100;
    min-width: 412px;
    max-width: 412px;
    min-height: 460px;
    max-height: 460px;
  }

  .close {
    height: 22px;
    width: 22px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }


  .section {
    z-index: 100;
    background: #ffffff;
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    margin-top: 40px;
    max-width: 362px;
    min-width: 362px;
    /* height: 460px; */
  }

  .title {
    margin-bottom: 22px;
  }

  .titleImg {
    margin-bottom: 16px;
  }

  .titlelogin {
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #1D1D1F;
  }

  .titlegoR {
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 6px;
    color: rgba(29, 29, 31, 0.7);
  }

  .subtitle {
    margin-top: 0.625rem;
    font-size: 15px;
    line-height: 19px;
    color: #1d1d1f;
    margin-bottom: 2.5rem;
    font-weight: 400;
    font-family: "Mulish";
    font-style: normal;
  }

  .el-input {
    display: flex !important;
    height: 36px;
    box-sizing: border-box;
    border-radius: 30px;
    display: block;
    flex-direction: row;
  }

  .Register :deep(.el-input__inner) {
    border: 1px solid rgba(29, 29, 31, 0.3);
    height: 36px;
    width: 100%;
    position: initial;
    font-size: 15px;
    color: #303133;
    padding: 8px 4px 8px 10px;


    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* color: rgba(29, 29, 31, 0.3); */
  }

  .pass :deep(.el-input__inner) {
    padding: 0 63px 0 10px;
  }

  .verificationInput {
    width: 182px;
  }

  .unverificationButton {
    width: 120px;
    height: 36px;
    border-radius: 4px;
    background: #F0F0F0;
    border: 1px solid rgba(29, 29, 31, 0.3);
    color: rgba(29, 29, 31, 0.6);
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }

  .verificationButton {
    width: 120px;
    height: 36px;
    background: #FFFFFF;
    border-radius: 4px;
    font-size: 15px;
    line-height: 32px;
    font-family: "Mulish";
    border: 1px solid #5452F6;
    text-align: center;
    cursor: pointer;

  }

  .verificationBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #5452F6;

  }

  .signUpButton {
    width: 312px;
    height: 36px;
    background: #5452F6;
    border-radius: 4px;
    color: #FFFFFF;
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: linear-gradient(0deg, #3432D6, #3432D6), #8BD06B;
    }

    &:active {
      background: linear-gradient(0deg, #3432D6, #3432D6), #8BD06B;
    }
  }

  .unsignUpButton {
    width: 312px;
    height: 36px;
    right: 452px;
    top: 121.42px;

    background: #CCCCD9;
    border-radius: 4px;
    text-align: center;
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 36px;
    color: #FFFFFF;
    cursor: no-drop;
    /* width: 312px;
    height: 36px;
    background: #5452F6;
    border-radius: 2px;
    color: #FFFFFF;
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: linear-gradient(0deg, #3432D6, #3432D6), #8BD06B;
    }

    &:active {
      background: linear-gradient(0deg, #3432D6, #3432D6), #8BD06B;
    } */
  }

  .goLogIn {
    font-size: 13px;
    color: #1d1d1f;
    text-align: center;
    margin-top: 16px;
    line-height: 16px;
    font-family: "Mulish";
  }

  .goLogInButton {
    color: #2f68eb;
    cursor: pointer;
    text-decoration: underline;
  }

  .goRules {
    font-size: 13px;
    line-height: 1rem;
    color: #909399;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    margin-left: -110px;
    /* top: 10px; */
    margin-top: 20px;
    flex-wrap: wrap;
    min-width: 664px;
  }

  /* 通过屏幕分辨率来判断 */
  .goRulesIns {
    color: #909399;
    margin-top: 10px;
    flex-wrap: wrap;
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */


    /* 2.0/三级文字-2.0 */

    color: rgba(29, 29, 31, 0.5);

  }

  .goRulesButton {
    color: #2f68eb;
    cursor: pointer;
    margin: 0 0.25rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .Register :deep(input::-webkit-input-placeholder) {
    font-size: 15px;
    line-height: 20px;
    color: rgba(29, 29, 31, 0.3);
  }

  .Register :deep(input:-moz-placeholder) {
    font-size: 15px;
    line-height: 20px;
    color: rgba(29, 29, 31, 0.3);
  }

  .Register :deep(input:-ms-input-placeholder) {
    font-size: 15px;
    line-height: 20px;
    color: rgba(29, 29, 31, 0.3);
  }

  .Register :deep(.el-checkbox__inner) {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.375rem;
  }

  .Register :deep(.el-checkbox__inner:after) {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 10px;
    left: 7px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg) scaleY(0);
    width: 4px;
    transition: transform 0.15s ease-in 0.05s;
    transform-origin: center;
    transform: rotate(45deg) scaleY(1);
  }

  .Register :deep(.el-form-item) {
    margin-bottom: 20px;
  }

  .Register :deep(.el-input__suffix) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Register :deep(.el-form-item__error) {
    padding-top: 0.6875rem;
    color: #d94a48;
    padding-left: 1.0625rem;
  }

  .eye-style {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 2px;
  }

  .uneye {
    margin-right: 21px;
    border: none;
    background: url("../../../assets/login/uneye.svg") no-repeat;
    height: 32px;
    width: 32px;
    background-size: 32px 32px;
  }

  .eyes {
    margin-right: 21px;
    border: none;
    background: url("../../../assets/login/eyes.svg") no-repeat;
    height: 32px;
    width: 32px;
    background-size: 32px 32px;
  }

  .showPass {
    line-height: 1;
  }

  .custom-loading .circular {
    margin-right: 6px;
    width: 18px;
    height: 18px;
    animation: loading-rotate 2s linear infinite;
  }

  .custom-loading .circular .path {
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: var(--el-button-text-color);
    stroke-linecap: round;
  }
</style>
<style>
  .el-form-item__error {
    padding: 0 !important;
    padding-top: 3px !important;
    height: 14px;
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #F95353;
  }
</style>