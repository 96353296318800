<template>
  <div class="home-top" v-click-outside="handleClickOutside">
    <div class="logo">
      <img src="https://pdnob.passfab.com/images/pdnob-logo.svg" alt="">
      <p>PDNob PDF Toolkit</p>
    </div>
    <div class="tool-win">
      <div class="purchase" @click="showPur">
        <div class="damand"></div>
        <div class="toPay">
          <span class="text">{{$t(`message.BuyNow`)}}</span>
        </div>
      </div>
      <div class="item cursor">
        <img src="../../assets/head/login.svg" alt="" v-if="$store.state.token" @click="openInfor" class="changelogin">
        <img src="../../assets/head/unlogin.svg" alt="" v-else @click="showLogin" class="changeunlogin">
        <!-- <i class="iconfont icon-yonghu personI" v-else @click="showLogin" v-cloak></i> -->
      </div>
      <el-popover popper-class="myPopover" :visible="visible">
        <template #default>
          <div>
            <div @click="handleClickOutside(1)" class="popoverBox">{{$t(`message.Contactus`)}}</div>
            <div @click="handleClickOutside(2)" class="popoverBox">{{$t(`message.About`)}}</div>
            <div @click="handleClickOutside(3)" class="popoverBox">PDNob PDF Online</div>
          </div>
        </template>
        <template #reference>
          <div class="item cursor aboutClass" @click="showAbout()">
            <!-- <i class="iconfont icon-caidan personI aboutBox" v-cloak></i> -->
            <img :src="moreSrc" alt="" class="changemore aboutBox">
          </div>
        </template>
      </el-popover>
      <div class="item cursor changeminimize" id="minbutton" @click="onChangeWindow('min')">
        <!-- <i class="iconfont icon-zuixiao personI" ></i> -->
        <img src="../../assets/head/minimize.svg">
      </div>
      <!-- <div class="item cursor" v-if="isMax" id="maxbutton" @click="onChangeWindow('max')">
        <svg t="1655259143272" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="2224" width="16" height="16">
          <path
            d="M768 170.666667H256c-46.933333 0-85.333333 38.4-85.333333 85.333333v512c0 46.933333 38.4 85.333333 85.333333 85.333333h512c46.933333 0 85.333333-38.4 85.333333-85.333333V256c0-46.933333-38.4-85.333333-85.333333-85.333333zM256 768V256h512v512H256z"
            fill="#2c2c2c" p-id="2225" />
        </svg>
      </div>
      <div class="item cursor" v-else @click="onChangeWindow('unmax')" id="unmaxbutton">
        <svg t="1655259165159" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="2427" width="16" height="16">
          <path
            d="M768 170.666667h-341.333333c-46.933333 0-85.333333 38.4-85.333334 85.333333v85.333333H256c-46.933333 0-85.333333 38.4-85.333333 85.333334v341.333333c0 46.933333 38.4 85.333333 85.333333 85.333333h341.333333c46.933333 0 85.333333-38.4 85.333334-85.333333v-85.333333h85.333333c46.933333 0 85.333333-38.4 85.333333-85.333334V256c0-46.933333-38.4-85.333333-85.333333-85.333333zM256 768v-341.333333h341.333333v341.333333H256z m512-170.666667h-85.333333v-170.666666c0-46.933333-38.4-85.333333-85.333334-85.333334h-170.666666V256h341.333333v341.333333z"
            fill="#2c2c2c" p-id="2428" />
        </svg>
      </div> -->
      <div class="item cursor" id="closebutton" @click="onChangeWindow('close')">
        <!-- <i class="iconfont icon-guanbi personI" v-cloak></i> -->
        <img src="../../assets/head/close.svg" alt="" class="changeclose">
        <!-- <svg t="1655259187200" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="2630" width="16" height="16">
          <path
            d="M571.733333 512l247.466667-247.466667c17.066667-17.066667 17.066667-42.666667 0-59.733333s-42.666667-17.066667-59.733333 0L512 452.266667 264.533333 204.8c-17.066667-17.066667-42.666667-17.066667-59.733333 0s-17.066667 42.666667 0 59.733333l247.466667 247.466667-247.466667 247.466667c-17.066667 17.066667-17.066667 42.666667 0 59.733333 8.533333 8.533333 21.333333 12.8 29.866667 12.8s21.333333-4.266667 29.866666-12.8l247.466667-247.466667 247.466667 247.466667c8.533333 8.533333 21.333333 12.8 29.866666 12.8s21.333333-4.266667 29.866667-12.8c17.066667-17.066667 17.066667-42.666667 0-59.733333L571.733333 512z"
            fill="#2c2c2c" p-id="2631" />
        </svg> -->
      </div>
    </div>
  </div>
  <div class="dialog">
    <el-dialog width="732px" height="460px" v-model="$store.state.showLogin" :before-close="handleClose"
      :show-close="false" :close-on-click-modal="false">
      <img src="../../assets/login/close.svg" alt="" class="close" @click="handleClose">
      <Login />
    </el-dialog>
  </div>
  <div v-show="isShowAbout || isShowInfor || $store.state.isShowPur" class="mark">

  </div>
  <div class="aboutDiv" v-show="isShowAbout">
    <div class="head">
      <span>PDNob PDF Toolkit</span>
      <img src="../../assets/homePage/close.svg" alt="" @click="isShowAbout=false">
    </div>
    <div class="content">
      <div class="content-head">
        <img class="content-logo" src="../../assets/homePage/logo.svg" alt="">
        <div class="content-con">
          <p>PDNob PDF Toolkit</p>
          <p>{{$t(`message.Version`)}}：1.0.1</p>
        </div>
      </div>
      <div class="content-mid">
        <p>{{$t(`message.processingToolbox`)}}</p>
        <p @click="goPath" class="href">https://www.pdnob.com</p>
      </div>
      <div class="content-buttom">
        <img src="../../assets/login/logo.svg" alt="">
        <span>Copyright © 2010 - 2023 PDNob All Rights Reserver</span>
      </div>
    </div>
  </div>
  <div class="inforDiv" v-show="isShowInfor">
    <div class="head">
      <span>My account</span>
      <img src="../../assets/homePage/close.svg" alt="" @click="isShowInfor=false">
    </div>
    <div class="custom-loading" v-if="isloading">
      <svg class="circular" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
          d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.45459 8.00014C1.45459 11.6151 4.38509 14.5456 8.00004 14.5456C11.615 14.5456 14.5455 11.6151 14.5455 8.00014C14.5455 4.38519 11.615 1.45469 8.00004 1.45469C4.38509 1.45469 1.45459 4.38519 1.45459 8.00014Z"
          fill="#0F0D2B" />
        <path
          d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16V14.5455C4.38505 14.5455 1.45455 11.615 1.45455 8C1.45455 4.38505 4.38505 1.45455 8 1.45455V0Z"
          fill="white" />
      </svg>
    </div>
    <div class="network" v-else-if="isnetwork">
      <img src="../../assets/homePage/network.svg" alt="">
      <p>{{$t(`message.Refreshfailed`)}}</p>
      <div class="buttom" @click="refresh">{{$t(`message.Retry`)}}</div>
    </div>
    <div class="content" v-else>
      <div class="content-head">
        <img class="content-logo" src="../../assets/homePage/per.svg" alt="">
        <div class="content-con">
          <p>{{$store.state.userInfo.email}}</p>
          <!-- <p>Account center</p> -->
        </div>
        <div class="content-head-right">
          <img src="../../assets/homePage/fresh.svg" alt="" @click="refresh" style="cursor: pointer;" class="refresh">
          <img src="../../assets/homePage/logout.svg" alt="" @click="logout" style="cursor: pointer;" class="logout">
        </div>
      </div>
      <div style="clear:both;"></div>
      <!-- <div v-if="userInfo.user_created_time == userInfo.vip_expired_time"> -->
      <!-- 创建时间==vip到期时间 ====》从未买过vip -->
      <div v-if="$store.state.userInfo.user_created_time == $store.state.userInfo.vip_expired_time">
        <div class="content-mid">
          <p>{{$t(`message.Permissioninformation`)}}<span>{{$t(`message.TrialVersion`)}}</span></p>
          <p>{{$t(`message.RemainingDailyFreeTrials`)}}{{$store.state.optCount.remaining}}</p>
        </div>
        <p class="content-warn">{{$t(`message.unlimitedusing`)}}</p>
        <div class="content-buttom" @click="clickUpgrade(2)">
          <span>{{$t(`message.upgradepermission`)}}</span>
        </div>
      </div>
      <!-- 当前时间>vip时间 ==》 过期 -->
      <div v-else-if="nowTime > $store.state.userInfo.vip_expired_time">
        <div class="content-mid">
          <p>{{$t(`message.Validuntil`)}}{{$store.state.userInfo.expired_time}}{{$t(`message.expired`)}}</p>
          <p>{{$t(`message.RemainingDailyFreeTrials`)}}{{$store.state.optCount.remaining}}</p>
          <p>{{$t(`message.Permissioninformation`)}}<span>{{$t(`message.TrialVersion`)}}</span></p>
          <p>{{$t(`message.Numberofdevicesused`)}}{{$store.state.userInfo.used_device_count}}</p>
          <p>{{$t(`message.Totalnumberofequipment`)}}{{$store.state.userInfo.total_device_count}}</p>
        </div>
        <div class="content-buttom" @click="clickUpgrade(1)">
          <span>{{$t(`message.upgradepermission`)}}</span>
        </div>
      </div>
      <div v-else>
        <div class="content-mid">
          <p>{{$t(`message.Validuntil`)}}{{$store.state.userInfo.expired_time}}</p>
          <p>{{$t(`message.Permissioninformation`)}}<span>{{$t(`message.MemberVersion`)}}</span></p>
          <p>{{$t(`message.Numberofdevicesused`)}}{{$store.state.userInfo.used_device_count}}</p>
          <p>{{$t(`message.Totalnumberofequipment`)}}{{$store.state.userInfo.total_device_count}}</p>
        </div>
        <div class="content-buttom" @click="clickUpgrade(1)">
          <span>{{$t(`message.Renewal`)}}</span>
        </div>
      </div>

    </div>

  </div>
  <div class="purchaseDiv" v-show="$store.state.isShowPur">
    <div class="head">
      <span>{{$t(`message.BECOMEAMEMBER`)}}</span>
      <img src="../../assets/homePage/close.svg" alt="" @click="closePur" class="close">
    </div>
    <div class="content">
      <div class="content-left">
        <ul class="content-ul">
          <li class="top-li left">{{$t(`message.Permission`)}}</li>
          <li class="small-li left">{{$t(`message.NumberofUsage`)}}</li>
          <li class="small-li left">{{$t(`message.FileSize`)}}</li>
          <li class="small-li left">{{$t(`message.NumberofFiles`)}}</li>
          <li class="small-li left">{{$t(`message.SoftwareUpgrade`)}}</li>
          <li class="small-li left" style="border: none;">{{$t(`message.TechSupport`)}}</li>
        </ul>
        <ul class="content-ul mid">
          <li class="top-li">{{$t(`message.FreeTrail`)}}</li>
          <li class="small-li">{{$t(`message.DayCount`)}}</li>
          <li class="small-li">300MB</li>
          <li class="small-li">8</li>
          <li><img src="../../assets/homePage/purright.svg" alt=""></li>
          <li style="border: none;"><img src="../../assets/homePage/purright.svg" alt=""></li>
        </ul>
      </div>

      <ul class="last-content-ul">
        <img class="right" src="../../assets/homePage/purchaseRight.svg" alt="">
        <li class="top-li" style="padding-left: 22px;">{{$t(`message.Member`)}}</li>
        <li class="small-li" style="padding-left: 22px;">{{$t(`message.Unlimited`)}}</li>
        <li class="small-li" style="padding-left: 22px;">{{$t(`message.Unlimited`)}}</li>
        <li class="small-li" style="padding-left: 22px;">{{$t(`message.Unlimited`)}}</li>
        <li style="padding-left: 22px;"><img src="../../assets/homePage/purright.svg" alt=""></li>
        <li style="border: none;padding-left: 22px;"><img src="../../assets/homePage/purright.svg" alt=""></li>
      </ul>

    </div>
    <div class="checkpurchase" id="purchase">
      {{$t(`message.Gettingaccountpermissions`)}}
    </div>
    <div class="content-buttom">
      <div class="left" @click="checkpurchase" v-if="$store.state.token">
        <span>{{$t(`message.Update`)}}</span>
      </div>
      <div class="left" @click="checkpurchase(1)" v-else>
        <span>{{$t(`message.Login`)}}</span>
      </div>
      <div class="right" @click="goPurchased">
        <span>{{$t(`message.CheckPrice`)}}</span>
      </div>
    </div>

  </div>
</template>
<script>
  import Login from "@/components/login/index.vue";
  import { dateFormat } from "@/utils/format.js";
  import '../../../src/assets/style/common.scss';
  import { gaEvent } from "@/utils/gaEvent";
  const electron = window.electron;
  export default {
    data() {
      return {
        pageName: "",
        visible: false,
        dialogVisible: false,
        isMax: true,
        activeClass: '',
        isShowAbout: false,//关于弹窗
        nowTime: '',//现在的时间
        isShowInfor: false,//账户信息
        isShowPur: false,//购买信息
        isnetwork: false,//刷新失败
        isloading: false,
        userInfo: {
          email: '',
          count: 0,
          used_device_count: 0
        },//用户信息
        showcheckpurchase: false,
        moreSrc: require("../../assets/head/more.svg")
      }
    },
    mounted() {
      // this.userInfo = this.$store.state.userInfo
      if (localStorage.getItem("authorization")) {
        this.refresh()
      }
      this.nowTime = Math.round(new Date().valueOf() / 1000)
      console.log("this.nowTime:", this.nowTime, this.userInfo);
      // console.log("this.userInfo.expired_time:", this.userInfo.vip_expired_time);
      // this.userInfo.expired_time = dateFormat(this.userInfo.vip_expired_time)
      // this.userInfo.count = this.userInfo.total_count - this.userInfo.used_count
    },
    components: {
      Login,
    },
    methods: {
      checkAppState() {
        return this.$store.state.appState;
      },
      goPath() {
        window.open("https://cbs.pdnob.com/go?pid=5808&a=dh")
      },
      showAbout(item, index) {
        this.visible = true
        console.log("展示about：", document.getElementsByClassName('aboutBox')[0]);
        // this.moreSrc = require("../../assets/head/moreh.svg")
        document.getElementsByClassName('aboutClass')[0].style.background = '#ECECFF'
      },
      goPurchased() {
        window.open("https://cbs.pdnob.com/go?pid=5808&a=db")
        gaEvent("click_buy", {
          event_category: "Buy",
          event_label: this.$store.state.currentPage,
        });
        this.$http
          .get('/app/user/polling').then(res => {
            console.log("发送轮询", rej);
          }).catch(rej => {
            this.$message.warning(rej.response.data.msg)
            console.log("发送轮询失败", rej);
          })
      },
      // 点击任意位置隐藏popover
      handleClickOutside(index) {
        switch (index) {
          case 1:
            window.open("https://cbs.pdnob.com/go?pid=5808&a=do")
            break;
          case 2:
            this.isShowAbout = true
            break;
          case 3:
            window.open("https://cbs.pdnob.com/go?pid=5808&a=dp")
            break;
        }
        this.visible = false;
        // this.moreSrc = require("../../assets/head/more.svg")
        document.getElementsByClassName('aboutClass')[0].style = '#ECECFF'
      },
      //打开登录窗口
      showLogin() {
        console.log("登录弹窗");
        this.dialogVisible = true
        this.$store.commit('changeStateShowLogin', true)
        gaEvent("login_popup", {
          event_category: "Login_Register",
          event_label: this.$store.state.currentPage,
        });
        this.$store.commit('setModalStatus', 1)
      },
      showPur() {
        window.open("https://cbs.pdnob.com/go?pid=5808&a=db")
        gaEvent("click_buy", {
          event_category: "Buy",
          event_label: this.$store.state.currentPage,
        });
        // this.$store.commit('changeIsShowPur', true)
      },
      //关闭登录
      handleClose(done) {
        console.log("关闭登录弹窗");
        this.$store.commit('changeStateShowLogin', false)
      },
      //放大缩小关闭
      onChangeWindow(type) {
        const remote = window.remote;

        switch (type) {
          case 'min': {
            console.log("缩小");

            electron.ipcRenderer.send('window-minimize')
            break;
          }
          case 'max':
            {
              electron.ipcRenderer.send('window-maximize')
              console.log("放大");
              this.isMax = !this.isMax
              break;
            }
          case 'unmax':
            {
              electron.ipcRenderer.send('window-unmaximize')
              console.log("中等");

              this.isMax = !this.isMax
              break;
            }
          case 'close':
            {
              const _this = this
              console.log("关闭");
              // if (_this.checkAppState() != "start" && _this.checkAppState() != "failConvert" && _this.checkAppState() != "failUpload" && _this.checkAppState() != "converted") {
              if (_this.checkAppState() == "converting") {
                console.log("------", _this.checkAppState());
                this.$confirm(this.$t(`message.commonTip.alertTip[5].poptext`), {
                  confirmButtonText: this.$t(`message.commonTip.alertTip[5].confirmButtonText`),
                  cancelButtonText: this.$t(`message.commonTip.alertTip[5].cancelButtonText`),
                  type: "none",
                  closeOnClickModal: false
                })
                  .then(() => {
                    let covertTime = Math.floor((new Date().getTime() - _this.$store.state.processTime) / 1000)
                    gaEvent("process_giveup", {
                      event_category: _this.$store.state.currentPage,
                      event_label: covertTime
                    });
                    electron.ipcRenderer.send('window-close')
                  })
                  .catch(() => {
                  });
              } else {
                electron.ipcRenderer.send('window-close')

              }
              break;
            }
        }
      },
      //获取权限信息
      openInfor() {
        // this.isloading = true
        this.isShowInfor = true
        this.refresh()
      },
      refresh() {
        this.isloading = true
        this.$http
          .get('/app/user/profile')
          .then(async (res) => {
            if (res.code == "200") {
              this.isloading = false
              this.isnetwork = false
              res.data.expired_time = dateFormat(res.data.vip_expired_time)
              res.data.count = res.data.total_count - res.data.used_count
              this.$store.commit('getUserInfo', res.data)
              console.log("moment:");
              console.log("moment:", dateFormat(res.data.vip_expired_time));
            }

          })
          .catch(() => {
            console.log("断网了");
            this.isloading = false
            this.isnetwork = true
          })
      },
      //退出登录
      logout() {
        // this.isloading = true
        this.$http
          .post('/app/user/logout', {})
          .then(async (res) => {
            this.$message.success('Logout successful!')
            if (res.code == "200") {
              // this.isloading = false
              // this.isShowInfor = false
              this.$store.commit('setModalStatus', '1')
              this.$store.commit('changeToken', '')
              this.$store.commit('getUserInfo', {
                vip_expired_time: '',
                email: ''
              })
              this.$store.commit('changeOptCount', {})
              localStorage.removeItem("authorization");
              localStorage.removeItem("userInfo");
              localStorage.removeItem("email");
              localStorage.removeItem("token");
              this.$store.commit('changeStateShowLogin', true)
              setTimeout(() => {
                window.location.reload()
              })
            }
          })
          .catch(() => {
            console.log("断网了");
            // this.isloading = false
            setTimeout(() => {
              this.$message.error('The network is abnormal. Please check the network connection.')
            })
          })
        // this.$store.dispatch('setUserInfo', { email: "" })
      },
      //关闭购买弹窗
      closePur() {
        this.$store.commit('changeIsShowPur', false)
      },
      checkpurchase(index) {
        if (index == 1) {
          this.$store.commit("setModalStatus", "1");
          this.showLogin()

          console.log("登录弹窗11");
          return
        }
        this.showcheckpurchase = true
        document.getElementById("purchase").innerHTML = this.$t(`message.Gettingaccountpermissions`);
        let token = localStorage.getItem('token')
        if (token) {
          document.getElementsByClassName('checkpurchase')[0].style.opacity = 1
          this.$http
            .get('/app/user/profile')
            .then(async (res) => {
              if (res.code == "200") {
                res.data.expired_time = dateFormat(res.data.vip_expired_time)
                res.data.count = res.data.total_count - res.data.used_count
                console.log("moment:", dateFormat(res.data.vip_expired_time));
                this.$store.commit('getUserInfo', res.data)
                let time = Math.round(new Date().valueOf() / 1000)
                console.log("res.data.vip_expired_time:", res.data.vip_expired_time, time, res.data.vip_expired_time > time);
                if (res.data.vip_expired_time > time) {
                  gaEvent("buy_success", {
                    event_category: "Buy",
                    event_label: this.$store.state.currentPage
                  });
                  console.log("验证通过");
                  this.$message.success(this.$t(`message.authorizationInformation`))
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000)
                } else {
                  console.log("验证失败");
                  document.getElementById("purchase").innerHTML = "Your authorization information was not detected, please purchase.";
                }
              }
            })
            .catch(() => {
              console.log("断网了");
              document.getElementById("purchase").innerHTML = "Your authorization information was not detected, please purchase.";
            })
        } else {
          this.$store.commit("setModalStatus", "1");
          this.showLogin()
        }

      },
      //升级权限
      clickUpgrade() {
        window.open("https://cbs.pdnob.com/go?pid=5808&a=db")
        console.log("埋点呢");
        gaEvent("click_buy", {
          event_category: "Buy",
          event_label: this.$store.state.currentPage,
        });
        // this.$store.commit('changeIsShowPur', true)
      },
      //检测是否购买vip
      checkStatus() {
        let token = localStorage.getItem('token')
        if (token) {
          this.$http
            .get('/app/user/profile')
            .then(async (res) => {
              if (res.code == "200") {
                // this.userInfo = res.data
                res.data.expired_time = dateFormat(res.data.vip_expired_time)
                res.data.count = res.data.total_count - res.data.used_count
                this.$store.commit('getUserInfo', res.data)
                console.log("moment:", dateFormat(res.data.vip_expired_time));
              }
            })
            .catch(() => {
              console.log("断网了");
            })
        } else {
          this.$store.commit("setModalStatus", "1");
          this.showLogin()
        }

      }
    },
    watch: {

    }
  }
</script>
<style lang="scss" scoped>
  .close {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    z-index: 9999;
  }

  .mark {
    position: absolute;
    width: 1224px;
    height: 592px;
    left: 0;
    top: 0;
    z-index: 20;
    background-color: #000000;
    opacity: 0.5;
  }

  .logo {
    padding: 12px 0;
    display: inline;
    vertical-align: middle;
    margin-right: 236px;

    img {
      vertical-align: unset;
      width: 36px;
      height: 20px;
      margin-right: 5px;

    }

    p {
      display: inline-block;
      font-size: 14px;
      vertical-align: top;
      font-family: 'Be Vietnam Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      /* identical to box height, or 20px */


      color: #1D1D1F;
    }
  }

  .home-top {
    display: flex;
    height: 44px;
    background: #EEF5FF;
    padding-left: 308px;
    -webkit-app-region: drag;

  }

  .tool-win {
    display: flex;
    /* padding: 0 10px; */
    justify-content: flex-end;


    /* -webkit-app-region: drag; */
    .purchase {
      margin: 9px 12px 0 0;
      width: 104px;
      height: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      background: linear-gradient(93.53deg, #FFDE9F 28.26%, #FFD187 61.9%, #FFB341 96.78%), #5452F6;
      box-shadow: inset 2px 2px 3px #FFFBD7;
      border-radius: 65px;
      cursor: pointer;
      -webkit-app-region: no-drag;

      .damand {
        width: 32px;
        height: 24px;
        background: url('../../assets/homePage/damind.svg');

        position: relative;
      }

      /* .toPay {
        width: 70%;
      } */

      .text {
        font-family: 'Microsoft YaHei UI';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        margin-left: 8px;
        color: #1D1D1F;
      }

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), linear-gradient(93.53deg, #FFDE9F 28.26%, #FFD187 61.9%, #FFB341 96.78%), #5452F6;
        box-shadow: inset 2px 2px 3px #FFFBD7;
      }

      &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(93.53deg, #FFDE9F 28.26%, #FFD187 61.9%, #FFB341 96.78%), #5452F6;
        box-shadow: inset 2px 2px 3px #FFFBD7;
      }
    }

    .item {
      /* width: 3rem; */
      text-align: center;
      width: 32px;
      height: 32px;
      margin-top: 7px;
      -webkit-app-region: no-drag;
      margin-right: 8px;
      border-radius: 4px;

      &:hover {
        /* content: url(../../assets/head/minimizeh.svg); */
        background-color: #ECECFF;
      }

      &:active {
        /* content: url(../../assets/head/minimizea.svg); */
        background-color: #DDDCFD;

      }

      .personI {
        margin-top: -10px;
        color: #525C66;
        -webkit-app-region: no-drag;
        font-size: 32px;

        &:hover {
          color: #3E3BFF
        }

        &:active {
          color: #3432D6;
        }
      }
    }
  }

  .popoverBox {
    width: 149px;
    height: 32px;
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1D1D1F;
    line-height: 32px;
    padding-left: 12px;
    cursor: pointer;

    &:hover {
      color: #5452F6 !important;
      background: #ECECFF;
    }
  }

  .aboutDiv {
    width: 480px;
    height: 300px;
    position: absolute;
    background: #FFFFFF;
    z-index: 1000;
    /* border: 0.5px solid rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.28), inset 0px 1px 1px rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    top: 146px;
    left: 392px;

    .head {
      width: 478px;
      height: 32px;
      border-bottom: 1px solid rgba(223, 224, 222, 0.6);
      display: flex;
      justify-content: space-between;

      span {
        display: inline-block;
        font-family: 'Microsoft YaHei';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 30px;
        margin-left: 16px;
        color: rgba(29, 29, 31, 0.5);
      }

      img {
        margin-right: 4px;
        cursor: pointer;
      }
    }

    .content {
      padding: 0 32px;

      .content-head {
        display: flex;
        padding: 20px 0;

        .content-logo {
          margin-right: 16px;
          width: 56px;
          height: 56px;
        }

        .content-con {
          display: flex;
          flex-direction: column;
          justify-content: center;

          :first-child {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;


            color: #1D1D1F;
          }

          :nth-child(2) {
            font-family: 'Microsoft YaHei';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;

            color: rgba(29, 29, 31, 0.5);

          }
        }
      }

      .content-mid {
        /* width: 536px; */
        word-break: break-all;

        :first-child {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #1D1D1F;
          margin-bottom: 12px;
        }

        :nth-child(2) {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;

          /* 2.0/主色-2.0 */

          color: #5452F6;
        }

        .href {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .content-buttom {
        margin: 58px 0 20px 0;

        img {
          width: 60px;
          height: 20px;
          margin-right: 16px;
        }

        span {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-align: center;

          color: rgba(29, 29, 31, 0.5);
        }

      }
    }
  }

  .inforDiv {
    width: 622px;
    height: 400px;
    position: absolute;
    background: #FFFFFF;
    z-index: 100;
    /* border: 0.5px solid rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.28), inset 0px 1px 1px rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    top: 96px;
    left: 321px;

    .head {
      width: 622px;
      height: 32px;
      border-bottom: 1px solid rgba(223, 224, 222, 0.6);
      display: flex;
      justify-content: space-between;

      span {
        display: inline-block;
        font-family: 'Microsoft YaHei';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 30px;
        margin-left: 16px;
        color: rgba(29, 29, 31, 0.5);
      }

      img {
        margin-right: 4px;
        cursor: pointer;
      }
    }

    .content {
      padding: 0 32px;

      .content-head {
        padding: 20px 0;

        .content-logo {
          width: 56px;
          height: 56px;
          float: left;
        }

        .content-con {
          line-height: 56px;
          margin-left: 14px;
          float: left;

          :first-child {
            /* font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 16px; */

            font-family: 'Microsoft YaHei';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #1D1D1F;
          }

          :nth-child(2) {
            font-family: 'Microsoft YaHei';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            /* 2.0/主色-2.0 */

            color: #5452F6;

          }
        }

        .content-head-right {
          /* display: flex;
          justify-content: center;
          align-items: center; */
          float: right;
          line-height: 56px;

          img {
            width: 24px;
            height: 24px;
          }

          .refresh {
            &:hover {
              content: url("../../assets/homePage/freshh.svg");
            }

            &:active {
              content: url("../../assets/homePage/fresha.svg");
            }
          }

          .logout {
            &:hover {
              content: url("../../assets/homePage/logouth.svg");
            }

            &:active {
              content: url("../../assets/homePage/logouta.svg");
            }
          }

          :nth-child(2) {
            margin-left: 12px;
          }


        }
      }

      .content-mid {
        margin-top: 24px;

        p {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #1D1D1F;
          margin-bottom: 8px;
        }
      }

      .content-warn {
        font-family: 'Microsoft YaHei';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 20px;

        color: rgba(29, 29, 31, 0.7);
      }

      .content-buttom {
        position: absolute;
        bottom: 28px;
        width: 140px;
        height: 28px;
        background: linear-gradient(93.56deg, #FFDE9F 2.93%, #FFD187 48.93%, #FFB341 96.64%), #FD9244;
        border-radius: 2px;
        text-align: center;
        left: 240px;
        cursor: pointer;

        span {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 28px;
          color: #1D1D1F;
        }

        &:hover {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), linear-gradient(93.53deg, #FFDE9F 28.26%, #FFD187 61.9%, #FFB341 96.78%), #5452F6;
          box-shadow: inset 2px 2px 3px #FFFBD7;
        }

        &:active {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(93.53deg, #FFDE9F 28.26%, #FFD187 61.9%, #FFB341 96.78%), #5452F6;
          box-shadow: inset 2px 2px 3px #FFFBD7;
        }
      }
    }
  }

  .purchaseDiv {
    width: 576px;
    height: 386px;
    position: absolute;
    background: linear-gradient(180deg, #E2E1FF 0%, #F9FBFF 100%);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    top: 103px;
    left: 324px;
    z-index: 1000;

    .head {
      width: 576px;
      height: 46px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;

      span {
        display: inline-block;
        font-family: 'Be Vietnam Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #1D1D1F;
        line-height: 44px;
        margin-left: 205px;
      }

      img {
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin-top: 10px;
      }

      .close {
        &:hover {
          content: url("../../assets/homePage/closeh.svg");

        }

        &:active {
          content: url("../../assets/homePage/closea.svg");

        }
      }
    }

    .content {
      margin: 0 20px;
      display: flex;
      width: 536PX;
      box-sizing: content-box;
      border-radius: 6px;
      background: #fff;

      .content-head {
        display: flex;
        padding: 20px 0;

        .content-logo {
          margin-right: 16px;
          width: 56px;
          height: 56px;
        }

        .content-con {
          display: flex;
          flex-direction: column;
          justify-content: center;

          :first-child {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;


            color: #1D1D1F;
          }

          :nth-child(2) {
            font-family: 'Microsoft YaHei';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;

            color: rgba(29, 29, 31, 0.5);

          }
        }
      }

      .content-mid {
        :first-child {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #1D1D1F;
          margin-bottom: 12px;
        }

        :nth-child(2) {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;

          /* 2.0/主色-2.0 */

          color: #5452F6;
        }
      }

      .content-left {
        width: 396px;
        display: flex;
        background-color: #FFFFFF;
        border-radius: 6px 0 0 6px;

        .content-ul {
          li {
            height: 40px;
            width: 198px;
            padding: 12px 0 0 0;
            border-bottom: 1px solid rgba(234, 234, 234, 0.4);
          }

          .top-li {
            font-family: 'Microsoft YaHei UI';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            color: #1D1D1F;
          }


        }

        .left {
          padding-left: 16px !important;
        }
      }

      .small-li {
        font-family: 'Microsoft YaHei UI';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;

        color: rgba(29, 29, 31, 0.7);
      }

      .last-content-ul {
        width: 140px;
        height: 244px;
        border-radius: 0 6px 6px 0;
        background: rgba(84, 82, 246, 0.05);
        box-shadow: inset 0px 2px 4px #E7E7FF;
        position: relative;

        :nth-child(2) {
          font-family: 'Microsoft YaHei UI';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          color: #1D1D1F;
        }

        li {
          height: 40px;
          padding: 12px 0 0 0;
          border-bottom: 1px solid rgba(234, 234, 234, 0.4);
        }

        .right {
          position: absolute;
          left: 80px;
          top: 0;
        }
      }

    }

    .content-buttom {
      margin-top: 12px;
      margin-right: 20px;
      display: flex;
      justify-content: right;

      .left {
        width: 110px;
        height: 32px;
        border: 1px solid #5452F6;
        border-radius: 80px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        color: #5452F6;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: #3E3BFF;
          color: #fff;
        }

        &:active {
          background: #3432D6;
          color: #fff;
        }
      }

      .right {
        width: 110px;
        height: 32px;

        background: linear-gradient(93.53deg, #FFDE9F 2.9%, #FFCB77 48.93%, #FFB341 96.67%), #5452F6;
        box-shadow: inset 2px 2px 3px #FFFBD7;
        border-radius: 35px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 33px;
        text-align: center;
        margin-left: 12px;
        color: #1D1D1F;
        cursor: pointer;

        &:hover {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), linear-gradient(93.53deg, #FFDE9F 2.9%, #FFCB77 48.93%, #FFB341 96.67%), #5452F6;
          box-shadow: inset 2px 2px 3px #FFFBD7;
        }

        &:active {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(93.53deg, #FFDE9F 2.9%, #FFCB77 48.93%, #FFB341 96.67%), #5452F6;
          box-shadow: inset 2px 2px 3px #FFFBD7;
        }
      }
    }
  }

  .custom-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 368px;
  }

  .custom-loading .circular {
    margin-right: 6px;
    width: 32px;
    height: 32px;
    animation: loading-rotate 2s linear infinite;
  }

  .custom-loading .circular .path {
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: var(--el-button-text-color);
    stroke-linecap: round;
  }

  .network {
    margin-top: 64px;
    text-align: center;

    p {
      margin-top: 20px;
      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;

      color: #1D1D1F;
    }

    .buttom {
      position: absolute;
      width: 140px;
      height: 28px;
      left: calc(50% - 140px/2);
      bottom: 28px;

      background: #5452F6;
      border-radius: 2px;
      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      cursor: pointer;
      /* fff */

      color: #FFFFFF;
    }
  }

  .checkpurchase {
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    margin: 12px 0 0 20px;

    opacity: 0;
    /* 2.0/一级文字-2.0 */

    color: #1D1D1F;
  }
</style>
<style>
  .dialog .el-dialog {
    /* background-color: red; */
    margin-top: 80px !important;
    border-radius: 4px;
  }

  .el-popover.el-popper {
    padding: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-radius: 10px !important;
  }

  .dialog .el-dialog__header {
    padding: 0 !important;
  }

  .dialog .el-dialog__body {
    padding: 0 !important;
  }

  .dialog .el-dialog__headerbtn {
    z-index: 1000;
  }

  .el-message {
    z-index: 99999 !important;
  }
</style>